import React, {useState} from 'react'
import { useTheme, Box } from '@mui/material'
import Header from '../../components/Header'
import ManagerialThreePhases from './ManagerialThreePhases'
import ContributionsPolicy from './ContributionsPolicy'
import ComplexitiesFailures from './ComplexitiesFailures'
import { Button } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useAuth0 } from '@auth0/auth0-react'
import LoginButton from '../../components/LoginButton'

const InsightsPractAndPol = () => {
    const theme = useTheme()
    const [visible, setVisible] = useState({
        0: false,
        1: false,
        2: false,
    })

    const handleClick = (event) => {
        setVisible({...visible, [event.target.value]: !visible[event.target.value]})
    }

    const {isAuthenticated} = useAuth0()

    // if (!isAuthenticated) {
    //   return (
  
    //               <div className="login-container">
    //               <p>Please login to access the site...</p>
    //               <LoginButton />
    //               </div>
  
  
    //   )
    // }


    return (
        <Box m="1.5rem 2.5rem">
      <Header title="INSIGHTS FOR PRACTITIONERS AND POLICYMAKERS" subtitle="" />
      <Box
        mt="40px"
        height="5vh"
        >
          <div className="insights-holder">
          

          <div className="insight-button">
          <Button variant="contained" style={{minWidth:'1400px', height:"100px"}} onClick={handleClick} value={0}>Which complexities, if ignored by managers, can contribute to the failure of XSPs? {visible[0] ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> } </Button>
          {visible[0] && (
            <div className="insight"><ComplexitiesFailures /></div>
          )
           }
          </div>

          <div className="insight-button">
          <Button variant="contained" style={{minWidth:'1400px', height:"100px"}}onClick={handleClick} value={1}>Managerial implications of considering a multi-theoretical perspective on XSP three phases {visible[1] ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }</Button>
          {visible[1] && (
            <div className="insight">
          <ManagerialThreePhases />
          </div>)}
          </div>


          <div className="insight-button">
          <Button variant="contained" style={{minWidth:'1400px', height:"100px"}} onClick={handleClick} value={2}>Contributions towards current policy debates {visible[2] ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }</Button>
          {visible[2] && (
            <div className="insight"><ContributionsPolicy /></div>
          )}
          </div>
          </div>


      </Box>
    </Box>
    )
}

export default InsightsPractAndPol
