import React, { useMemo, useState, useEffect } from "react"
import { Box, useTheme } from "@mui/material"
import Header from "../../components/Header"
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "../../components/LoginButton";
import LogoutButton from "../../components/LogoutButton";
import axios from "axios";
import { useGetPapersQuery } from "../../state/api";
import BasicTable from "../../components/BasicTable";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Multiselect from "multiselect-react-dropdown";


const uploadToServer = (file, onUploadProgress) => {
  let formData = new FormData();

  formData.append("file", file);

  return axios.post(`${process.env.REACT_APP_BASE_URL}/api/csv/upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};



const Admin = () => {

  const { user, isAuthenticated, isLoading } = useAuth0();
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [options, setOptions] = useState({})
  const [filters, setFilters] = useState({})

  const { data, isFetching } = useGetPapersQuery()
  console.log("🚀 ~ file: index.jsx:34 ~ About ~ data:", data)

  useEffect(() => {
    if (!data || isFetching) {
      return
    }

    // const seen = []

    // const journalOptions = data.reduce((accumulator, entry, idx) => {
    //   if (seen.includes(entry.journal)) {
    //     return accumulator
    //   }
    //   seen.push(entry.journal)
    //   return [...accumulator, {key: entry.journal, id: idx}]
    // }, [])

    const journalOptions = data.reduce((accumulator, entry, idx) => {
      if (accumulator.includes(entry.journal)) {
        return accumulator
      }
      return [...accumulator, entry.journal]
    }, [])

    setOptions({...options, "journal": journalOptions})
  }, [data])


  const handleDataCheck = () => {
    const exclude = ["__v", "createdAt", "updatedAt", "_id"]
    console.log(data)
    console.log(data[0])
    console.log(typeof data[0])
    const cols = Object.keys(data[0]).reduce((accumulator, key) => {
      if (exclude.includes(key)) {
        return accumulator
      }

      if (key === "Article Link") {
        return [...accumulator, {
          Header: key,
          accessor: key,
          Cell: ({value}) => (
            <a href={value} target="_blank">{value}</a>
          )
        }]
      }

      return [...accumulator, {
        Header: key,
        accessor: key
      }]
    }, []
    )

    console.log(cols)

  }

  const selectFile = (event) => {
    setSelectedFiles(event.target.files);
  };

  const upload = () => {
    let currentFile = selectedFiles[0];

    setProgress(0);
    setCurrentFile(currentFile);

    uploadToServer(currentFile, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then(async (response) => {
        setMessage(response.data.message);
      })
      .catch(() => {
        setProgress(0);
        setMessage("Could not upload the file!");
        setCurrentFile(undefined);
      });

    setSelectedFiles(undefined);
  };

  // const handleSelect = (name, e) => {
  //   console.log(e[0])
  //   const copy = filters[name] || [];
  //   copy.push(e[0].key);
  //   setFilters({...filters, [name]: copy });
  // };

  // const handleRemove = (name, e) => {
  //   console.log(e)
  //   let copy = filters[name] || [];
  //   let index = copy.indexOf(e[0].key);
  //   copy.splice(index, 1);
  //   setFilters({...filters, [name]: copy });
  //   };



  // const columns = useMemo(() => cols, [])



if (isLoading || isFetching) {
  return <div>Loading ...</div>;
}

// const exclude = ["__v", "createdAt", "updatedAt", "_id"]
// const cols = Object.keys(data[0]).reduce((accumulator, key) => {
//   if (exclude.includes(key)) {
//     return accumulator
//   }

//   return [...accumulator, {
//     Header: key,
//     accessor: key
//   }]
// }, []
// )
  return (
    <Box m="1.5rem 2.5rem">
      <Header title="ADMIN" subtitle="" />
      <div>
      {currentFile && (
        <div className="progress">
          <div
            className="progress-bar progress-bar-info progress-bar-striped"
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: progress + "%" }}
          >
            {progress}%
          </div>
        </div>
      )}

      <label className="btn btn-default">
        <input type="file" onChange={selectFile} />
      </label>

      <button
        className="btn btn-success"
        disabled={!selectedFiles}
        onClick={upload}
        style={{marginLeft:"10px"}}
      >
        Upload
      </button>

      <div className="alert alert-light" role="alert">
        {message}
      </div>
      </div>
      <button onClick={handleDataCheck}>log data</button>


      {/* <Multiselect
      onSelect={(e) => handleSelect("journal", e)}
      onRemove={(e) => handleRemove("journal", e)}
      options={options.journal}
      displayValue="key"
      showCheckbox={true}
      emptyRecordMsg={"Maximum nominees selected !"}
    /> */}

      {/* <div>
        <BasicTable columns={cols} data={data} />
      </div> */}
    </Box>
  )
}

export default Admin
