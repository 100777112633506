import React, {useState} from 'react'
import Header from '../../components/Header'


function ExpansionPhase() {
  const [text1, setText1] = useState(false)
  const [text2, setText2] = useState(false)
  const [text3, setText3] = useState(false)
  const [text4, setText4] = useState(false)
  const [text5, setText5] = useState(false)
  const [text6, setText6] = useState(false)
  const [text7, setText7] = useState(false)
  const [text8, setText8] = useState(false)
  const [text9, setText9] = useState(false)

  return (
    <div>
            <p>Click each <em>subheading</em> to reveal more information.</p>

    <table>
      <tbody>
        <tr>
          <th><strong>Key questions</strong></th>
          <th><strong>Core findings</strong></th>
        </tr>
        <tr>
          <td><strong>What factors contribute to the creation and dissemination of value in XSPs?</strong></td>
          <td>
          <p onClick={(e) => setText1(!text1)}>
            <em><strong>Capabilities and experience:</strong></em> {text1 && "conflict/contract management capability (Cornforth et al., 2015; Kort and Klijn, 2011); partnership capabilities and capacities (Alonso and Andrews, 2018; Brinkerhoff, 1999; Lazzarini et al., 2020; Murphy and Arenas, 2010), XSP experience and organizational capacity (Almog-Bar and Schmid, 2018; Fredericksen and London, 2000; Murphy et al., 2015; Nolte and Boenigk, 2013), conveners' ability to strengthen the capacity of the public sector and to facilitate learning together (Sonday and Wilson-Prangley, 2018); management routines and efforts (Kelman et al., 2013; Liu et al., 2018; Steijn et al., 2011); partner diversity and target intensity (Alonso and Andrews, 2018); the intensity of the collaboration (Austin and Seitanidi, 2012; Selden et al., 2006); the diversity of management strategies and board characteristics i.e., stakeholder, racial, gender diversity (Gazley et al., 2010; Kort and Klijn, 2011), partner's social capital and network relationships (Squazzoni, 2009)"}
            </p>
            <p onClick={(e) => setText2(!text2)}>
            <em><strong>Shared resources: </strong></em> {text2 && "the amount of knowledge for both parties (Austin and Seitanidi, 2012; Nolte and Boenigk, 2013; Weber et al., 2017); conveners' creation of spaces and circulation of capital) Trujillo, 2018(; material and financial resources (Cabral et al, 2019; Chapman and Varda, 2017; Gazley, 2010; Schiller and Almog-Bar, 2013; Weber et al., 2017); relational and social embeddedness (Alonso and Andrews, 2020; Lashitew et al., 2020; Liu et al., 2018); relation-specific investments (Weber et al., 2017); partners' status, centrality, structural holes (Arya and Lin, 2007); Social beneficiaries engagement  and equal access (Lindsay et al., 2018; Wilson, 2012), intergenerational fairness in accrued costs and benefits (Gilmour, 2012)"}
            </p>
            <p onClick={(e) => setText3(!text3)}>
            <em><strong>Governance:</strong></em> {text3 && "self-enforcing safeguards (e.g., trust) (DiVito et al., 2021; Weber et al., 2017); mutual trust and relational commitment (Liu et al., 2018); monitoring, evaluation systems, and accountability structures (Arenas et al., 2020; MacDonald et al., 2019; Page et al., 2015; Rein and Stott, 2009); collaborative and deliberative decision-making processes (Amirkhanyan, 2008; Bingham et al., 2005; Boyer et al., 2016; Emerson et al., 2011; MacDonald et al., 2019); contractual governance forms with explicit targets (Alonso and Andrews, 2018; Feilhauer and Hahn, 2021), structural alignment i.e., the extent to which the governance structure fits the partnership requirements (Kivleniece and Quelin, 2012; Mirońska and Zaborek, 2019), procedural rationality and justice in decision-making (Page et al., 2015; Van den Oever and Martin, 2018)"}
            </p>
            <p onClick={(e) => setText4(!text4)}>
            <em><strong>Conflict management:</strong></em> {text4 && "emergence of different institutional logics (Ashraf et al., 2017); private and public opportunism, social activism of external stakeholders (Andreassen, 2008; Kivleniece and Quelin, 2012); politics in an organization's decision-making (Almog-Bar and Schmid, 2018; Grimshaw et al., 2002; Schiller and Almog-Bar, 2013; Van den Oever and Martin, 2018); complexity involved in the life-cycle management of XSPs (Da Cruz and Marques, 2012); aligned missions and goals (Chapman and Varda, 2017; Gazley, 2010; Mirońska and Zaborek, 2019)"}
            </p>
          </td>
        </tr>
        <tr>
          <td><strong>How XSPs contribute to the value expansion beyond the partnership?</strong></td>
          <td>
          <p onClick={(e) => setText5(!text5)}>
            <em><strong>Capability development:</strong></em> {text5 && "XSPs' formation can increase organizational capacities (Sanzo et al., 2015; Shumate et al., 2018) and dynamic capabilities of partners by sensing, interacting, learning from, and changing based on interactions with stakeholders (Dentoni et al., 2016) and can also lead to competitive knowledge spillovers (Ghere, 2002; Oxley and Sampson 2004; Stadtler and Van Wassenhove, 2016; Stadtler 2018) and detrimental learning races (Kim and Parkhe 2009); Resilience of Socio-Ecological Systems (Dentoni et al., 2021); over-dependence on the developed routines (Domberger et al., 1997)"}
            </p>
            <p onClick={(e) => setText6(!text6)}>
            <em><strong>Partnership experience:</strong></em> {text6 && "multiple, consecutive XSP formation increases with accrued public and private capabilities, and quality of national institutions (Quelin et al., 2019); engaging in social initiatives single handedly occurs when prior XSPs failed to meet their objectives (Egels-Zanden and Wahlqvist, 2007); global expansion (Stone and Ladi, 2015)"}
            </p>
            <p onClick={(e) => setText7(!text7)}>
            <em><strong>Intangible benefits and costs:</strong></em> {text7 && "increased reputation for partners (Clarke and MacDonald, 2019; Mirońska and Zaborek, 2019), threat to nonprofit organizational legitimacy (Herlin, 2015), societal equity (Andrews and Entwhistle, 2010; Lund-Chaix and Gelles, 2014), persistence of gender discrimination (Johnston, 2017); empowerment of nonprofits (Fyall, 2016)"}
            </p>
            <p onClick={(e) => setText8(!text8)}>
            <em><strong>Regulation formation:</strong></em> {text8 && "XSPs can create procedures for unregulated contexts through the cooperation among firms (Faerman et al., 2001)"}
            </p>
            <p onClick={(e) => setText9(!text9)}>
            <em><strong>Innovation:</strong></em> {text9 && "XSPs can increase social innovation (Lashitew et al., 2020; Phillips et al, 2019), upgrade the products of those actors that have ties with XSPs (McDermott et al., 2009; Sanzo et al., 2015), Stable and non-stable innovation ecosystem (Oskam et al., 2021); threat to NGO sector inventiveness including spontaneity, variety, and unpredictability (Ramanath, 2009), increased complexity nonprofits' decision-making environment (Stone, 2000)"}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
  )
}

export default ExpansionPhase