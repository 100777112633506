import React from "react"
import FlexBetween from "../../components/FlexBetween"
import Header from "../../components/Header"
import {
  DownloadOutlined,
  Email,
  PointOfSalen,
  PersonAdd,
  Traffic,
  PointOfSale,
} from "@mui/icons-material"
import { Box, useTheme, Typography, Button, useMediaQuery } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import BreakdownChartPapers from "../../components/BreakdownChartPapers"
import OverviewChartPapers from "../../components/OverviewChartPapers"
import { useGetPapersQuery } from "../../state/api"
import StatBox from "../../components/StatBox"

const Dashboard = () => {
  const theme = useTheme()
  const isNonMediumScreens = useMediaQuery("(min-width: 1000px")
  const { data, isLoading } = useGetPapersQuery()
  console.log("🚀 ~ file: index.jsx:23 ~ Dashboard ~ data:", data)

  const columns = [
    {
      field: "title",
      headerName: "Title",
      flex: 1.5,
    },
    {
      field: "journal",
      headerName: "Journal",
      flex: 1,
    },
    {
      field: "year",
      headerName: "Publication Year",
      flex: 0.7,
    },
    {
      field: "authors",
      headerName: "Authors",
      flex: 1,
    },
    {
      field: "methods",
      headerName: "Research Methods",
      flex: 0.4,
    },
    {
      field: "geoLocation",
      headerName: "Geo Location of Sample",
      flex: 0.5,
    },
    {
      field: "collaborationType",
      headerName: "Type of Collaboration",
      flex: 0.5,
    },
    {
      field: "issue",
      headerName: "Issue Addressed",
      flex: 1,
    },
  ]

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

        {/* <Box>
        <Button
          sx={{
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.background.alt,
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
        >
          <DownloadOutlined sx={{ mr: "10px" }} />
          Download Reports
        </Button>
      </Box> */}
      </FlexBetween>

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="200px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <Typography
            padding="0 0.6rem"
            fontSize="0.8rem"
            sx={{ color: theme.palette.secondary[200] }}
          >
            Breakdown of papers published by journal
          </Typography>
          <BreakdownChartPapers isDashboard={true} />
        </Box>
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
        >
          <Typography
            padding="0 0.6rem"
            fontSize="0.8rem"
            sx={{ color: theme.palette.secondary[200] }}
          >
            Frequency of paper publication across years
          </Typography>
          <OverviewChartPapers isDashboard={true} />
        </Box>

        {/* Row Two */}
        <Box
          gridColumn="span 12"
          gridRow="span 3"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              borderRadius: "5rem",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            loading={isLoading || !data}
            getRowId={(row) => row._id}
            rows={data || {}}
            columns={columns}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default Dashboard
