import React from 'react'
import Header from '../../components/Header'

function ContributionsPolicy() {
  return (
    <div>
        <table>
        <tbody>
          <tr>
            <td><em><strong>1) Whether XSPs may be rendered mandatory when the need for addressing the grand challenge is pressing?</strong></em></td>
            <td>
              <p>
              A body of knowledge underpinned by the assumption of XSPs being characterized by their voluntary nature. Therefore, offers no direct insight into this debate, as it does not hold immediate insights into the process or outcomes of XSPs that are not voluntary. Empirically, we may locate examples when mandated XSPs have generated positive outcomes e.g., in the global COVID-19 pandemic; Henry, 2023), and to overcome the monopolies of established dominant actors in the AI field; Mazzucato et al., 2023). Multiple examples whereby the state has offered incentives for formation of XSPs (Mazzucato, Andreoni, Conway, 2021), e.g., 'Ontario Health Teams' in Canada (Aristidou and Sarta, 2023) i.e., 'semi-mandated' being in the liminal space between voluntary and mandated. 
              </p>
              <ul>
                <li>A)	By relaxing the 'voluntary' characteristic of XSPs in order to account also for the reality of this liminal space, and by identifying a range of possibilities (voluntary, semi-mandated, mandated) instead of a binary option (voluntary/mandated), insights from past XSP research may be introduced into this debate thoughtfully and be empirically tested to add a valuable extension to our knowledge of the XSP research process and impact in cases of semi-mandated and mandated XSPs.</li>
                <li>B)	If, the magnitude of the aimed 'shared good' and the urgency of the grand challenge may be theoretically leveraged to relax the voluntary assumption in XSP research, as these possibilities implicitly suggest, then we are required to first critically examine what constitutes “shared public good” and “grand challenge”. We are not highlighting mere definitional debate, but rather an onto-epistemological concern with the potential to impact the debate between voluntary and mandated XSP initiatives, and the potential to affect the directionality of XSPs' purpose. Water management, seen as a grand challenge and shared public aim to be addressed through XSPs (e.g., Kaplan et al, 2006 and 2007) may not be seen as an equally urgent public issue in some contexts. Artificial Intelligence, the regulation of which is seen as a public aim to be addressed through cross-sector partnering (e.g., UK Government Office for Artificial Intelligence, 2023; Mikhaylov, Esteve, and Campion, 2018), might not present a concern of the same magnitude in different contexts. </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td><em><strong>2)The future of the third sector in a world where increasingly 'data' becomes a key resource: the role of XSP theoretical interstice.</strong></em></td>
            <td>
              <p>
              The role of “big data”-powered algorithms in transforming the public sector is being celebrated (UK AI Office, 2022). While moving in this new direction for XSPs, concerns are raised that the large datasets required to train algorithms are predominantly in the public and private sectors, thus rendering third sector organizations less desirable XSP partners compared to previously. Empirically, this development threatens the diversity of cross-sector partners in XSPs where data-rich technologies are central. We provide some insights derived from the theoretical interstice between the stakeholder and the capabilities perspectives. This interstice may contribute expansive and impactful strategies, such as initiating associations that will serve to increase the reputation of the third sector when it comes to producing, managing, and leveraging data.
              </p>
              <ul>
                <li>
                A)	One way would be to initiate locally-based consortia of third sector organizations. Furthermore, third sector may proactively engage in the process of data generation (e.g., collecting the attributes and preferences of communities when it comes to important societal issues). By expanding their collective resources and by co-opting the resources within the community in reach, firms in nonprofit sectors could increase their access to those resources (data) which are key for staying relevant in the XSP field as attractive partners for cross-sector collaborations. 
                </li>
                <li>
                B)	In addition, third sector organizations wishing to position themselves as desirable partners in such data-rich XSPs might consider ways to further legitimize themselves in regard to big data management. In regard to large datasets of sensitive data, e.g., such as those required to co-deliver a health service, the data holders (typically the state) may not have enough evidence yet to trust third sector organizations with data access. In order to address these concerns, the third sector could self-organize to self-regulate and legitimize themselves in regard to data-rich XSPs. This strategy has benefited firms in private sectors such as the chemical industry, where firms self-regulated to protect their common reputation (Barnett and King, 2008). 
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
        </table>
    </div>
  )
}

export default ContributionsPolicy