import React from 'react'
import Header from '../../components/Header'


function ComplexitiesFailures() {
  return (
    <div>
        <img style={{width: "100%"}}src="images/ComplexitiesFailureDiagram.png" alt="" />

    </div>
  )
}

export default ComplexitiesFailures