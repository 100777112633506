import React, {useState} from 'react'
import Header from '../../components/Header'


function ManagementPhase() {
  const [text1, setText1] = useState(false)
  const [text2, setText2] = useState(false)
  const [text3, setText3] = useState(false)
  const [text4, setText4] = useState(false)
  const [text5, setText5] = useState(false)
  const [text6, setText6] = useState(false)
  const [text7, setText7] = useState(false)
  const [text8, setText8] = useState(false)
  const [text9, setText9] = useState(false)
  const [text10, setText10] = useState(false)
  const [text11, setText11] = useState(false)
  const [text12, setText12] = useState(false)
  const [text13, setText13] = useState(false)
  const [text14, setText14] = useState(false)
  const [text15, setText15] = useState(false)
  const [text16, setText16] = useState(false)
  const [text17, setText17] = useState(false)
  const [text18, setText18] = useState(false)
  const [text19, setText19] = useState(false)
  const [text20, setText20] = useState(false)
  const [text21, setText21] = useState(false)
  const [text22, setText22] = useState(false)
  const [text23, setText23] = useState(false)

  return (
    <div>
      <p>Click each <em>subheading</em> to reveal more information.</p>
    <table>
      <tbody>
        <tr>
          <th>Key questions</th>
          <th>Core findings</th>
        </tr>
        <tr>
          <td><strong>What are the main internal sources of tensions that partners face in XSPs?</strong></td>
          <td>
            <p onClick={(e) => setText1(!text1)}>
            <strong><em>Logic complexity:</em></strong>
            {text1 && "institutional logics (Ashraf et al., 2017; Jay, 2013); relative emphasis on the logics by each partner such as founding team's social imprinting i.e., emphasis on social aspect of the partnership (Battilana et al., 2015; Koppenjan and de Jong, 2018; Rivera-Santos et al., 2017)"}
            </p>
            <p onClick={(e) => setText2(!text2)}>
            <strong><em>Frame incompatibility:</em></strong> 
            {text2 &&
            "identity frames (Klitsie et al., 2018); values (Mitzinneck and Besharov, 2019) and cultures (Murphy and Arenas, 2010), degree of incompatibility in emotions, identities, cultures, values, and goals and the number of incompatible emotions, identities, cultures, values, and goals (Carpenter, 1984; Hesse et al., 2019; Reynaers, 2013); emotional incidents (Sloan and Oliver, 2013); low flexibility of members' interests and identities (Koschmann et al., 2012); interpretative processes in individuals with different genders (Johnston, 2017)"}
            </p>
            <p onClick={(e) => setText3(!text3)}>
            <strong><em>Practice differences:</em></strong> 
            {text3 && "partners' different communication approaches within the context of XSP (Koschmann et al., 2012); different approaches to implementations of tasks and collaboration methods to achieving objectives (Ahmadsimab and Chowdhury, 2019; Gazley, 2008; Gulbrandsen et al., 2015); Managers' premature closure of conversations, and XSP low intertextual receptivity (Koschmann et al., 2012); lack of interaction (Koppenjan, 2005) Goal divergence: incompatible goals of partnering organizations (Cabral, 2017; Hassel, 1997; Murphy et al., 2015; Gulbrandsen et al., 2015)"}
            </p>

            <p onClick={(e) => setText4(!text4)}>
            <strong><em>Power imbalance and politics:</em></strong>   
            {text4 && "conflicts related to the insertion of politics into interactions among parties and individuals (Van den Oever and Martin, 2018) and from the lack of balance in partners' relation sources of power (Cho and Gillespie, 2006; Nicholls and Huybrechts, 2016; Paik et al., 2018)"}
            </p>


            <p onClick={(e) => setText5(!text5)}>
            <strong><em>Contractual and trust-based frictions:</em></strong> 
            {text5 &&
            "because of partners' opportunism and lack of trust, ex-post hold-up and violation of terms in the contractual agreements, and agency costs (Kivleniece and Quelin, 2012; Lamothe and Lamothe, 2012; O'Regan and Oster, 2000; Rivera-Santos et al., 2017); partners' perception of peers' collaboration and performance (Fonti et al., 2017; Thomas et al., 2010); employees' free-riding in XSP communications (Koschmann et al., 2012); different governance modes (Babiak and Thibault, 2009; Lowndes and Skelcher, 1998; ); perceptions of unfair behavior by the partner (Domberger et al., 1997); accountability structures' failures (Koliba et al., 2011), inertia of initial governance structures (Takahashi and Smutny, 2002)"}
            </p>

            <p onClick={(e) => setText6(!text6)}>
            <strong><em>Learning tensions:</em></strong>
            {text6 &&
            "tensions rooted in different learning strategies and processes (Caldwell et al, 2017; van Tulder and Keen, 2018), and in how the knowledge should be integrated and shared (Inkpen and Pien, 2006); the complexity of learning strategies (van Tulder and Keen, 2018); low openness of employees to the knowledge that contradicts prior employees' belief (Inkpen and Pien, 2006)"
            }</p>

          </td>
        </tr>
        <tr>
          <td><strong>What are the main external sources of tensions that partners face in XSPs?</strong></td>
          <td>
            <p onClick={(e) => setText7(!text7)}>
            <em><strong>Legitimacy challenges:</strong></em> {text7 && "arising from external stakeholders' negative evaluations about the partnership's structure and objectives, or the partners (Baumann- Pauly et al., 2017; Gillespie et al., 2020; Kim et al., 2012; Logue and Grimes, 2019; Mena and Palazzo, 2012); consumers' perception of partnerships' lower levels of familiarity and activity fit is negatively associated with their perceived public-serving and social legitimacy (Kim et al., 2012)"}
            </p>
            <p onClick={(e) => setText8(!text8)}>
            <em><strong>Logic dominance:</strong></em> {text8 && "Social activists' pressure for an XSP to conform to specific logics (Hesse et al., 2019); community and external beneficiaries' pressures to change the processes and outcomes of XSPs (Cornelius and Wallace, 2010; Rivera-Santos et al., 2017)"}
            </p>
            <p onClick={(e) => setText9(!text9)}>
            <em><strong>Authority violation:</strong></em> {text9 && "inconsistency with public standards regarding the appropriate exercise of public authority leads to more illegitimacy (Gillespie et al., 2020)"}
            </p>
            <p onClick={(e) => setText10(!text10)}>
            <em><strong>Stakeholders' power:</strong></em> {text10 && "Higher levels of community and external beneficiary's voice and disruption (Cornelius and Wallace, 2010; Rivera-Santos et al., 2017; Turner et al., 2016); social movements and foreign governments power over the domestic XSPs (Boghossian and Marques, 2019)"}
            </p>
            <p onClick={(e) => setText11(!text11)}>
            <em><strong>Conveners' frictions:</strong></em> {text11 && "brokers' “role drift” through replacing partners' cross-sector tasks and decision makings with unilateral and under-performing broker-based tasks (Stadler and Karakulak, 2020)"}
            </p>
          </td>
        </tr>
        <tr>
          <td><strong>How can internal tensions be resolved? </strong></td>
          <td>
          <p onClick={(e) => setText12(!text12)}>
            <em><strong>Frame management:</strong></em> {text12 && "keeping both logics through hiring of employees who hold neither logic and socializing them to hold both (Battilana and Dorado, 2010); prioritizing logics (Reay and Hinings, 2005; Townley, 2002) and oscillating between selected logics (Jay, 2013; Murphy et al., 2015) or between dominating and consensus-based orchestration modes (Reypens et al., 2019); confronting, reinterpreting, and changing frames through structured flexibility (Smith and Besharov, 2019) and change of institutional blueprints (Logue and Grimes, 2019); embracing and tolerating conflicting logics through co-creating the XSP processes and goals (Nicholls and Huybrechts, 2016); creating a multivocal identity (Logue and Grimes, 2019; Martin, 2011) and maintaining optimal frame plurality through frame variation, addition, retention, deletion (Klitsie et al., 2018); integrating frames into a unique frame through defiance, selective coupling, and innovation (Ahmadsimab and Chowdhury, 2019; Besharov, 2014; Gray et al., 2015; Le Ber and Branzei, 2010; Mair et al., 2012; Moss, Short, Payne, and Lumpkin, 2011; Stadtler and Van Wassenhove, 2016); compromising frames by reaching to an acceptable level of collaboration (Mitzinneck and Besharov, 2019); relational positioning and discursive framing (Reissner, 2019); integration, re-culturation, and separation (Parker and Selsky, 2004)"}
            </p>
            <p onClick={(e) => setText13(!text13)}>
            <em><strong>Effective communication and emotion guidance:</strong></em> {text13 && "creating 'spaces for negotiation and trust building (Ansell and Gash, 2008; Battilana et al., 2015; Gillett et al., 2019; Simo and Bies, 2007); navigating employees' emotions by asking about their emotions, facilitating the disclosure of their emotions, being receptive to listen to their emotions, and valuing their emotions (Sloan and Oliver, 2013); motivating an interactive discourse among partners' employees (Kapucu, 2006; Nicholls and Huybrechts, 2016 Nolte and Boenigk, 2011); communication openness about the tensions (Battilana et al., 2015); XSP naming that aligns with an XSP's mission, a coherent narrative and XSP authoritative text that resonates with organizations' members (Koschmann et al., 2012)"}
            </p>
            <p onClick={(e) => setText14(!text14)}>
            <em><strong>Practice demarcation:</strong></em> {text14 && "organizing groups based on their values, i.e. structural compromise (Mitzinneck and Besharov, 2019); increasing the practice flexibility, joint piloting and modifying organizational practices (Ahmadsimab and Chowdhury, 2019; Page, 2003); narrowly specifying the employees' activities and negotiating activity scope (Rivera-Santos et al., 2017); familiarization and professionalization of employees (Hesse et al., 2019); ; deliberate role (re)calibrations to sustain the momentum and overcome temporary failure (Le Ber and Branzei, 2010); internal transparency (Reynaers and Grimmelikhuijsen, 2015)"}
            </p>
            <p onClick={(e) => setText15(!text15)}>
            <em><strong>Enhancing managerial capabilities:</strong></em> {text15 && "contract management and execution capabilities (Bloomfield, 2006; Cabral, 2017); integrative leadership and bargaining capabilities (Villani et al., 2017; Waring et al., 2013); stakeholder diversity and expectation management capabilities (Cabral et al, 2019); addressing management decision-making rigidities (Ungureanu et al., 2019), managers' boundary spanning abilities (Noble and Jones, 2006)"}
            </p>
            <p onClick={(e) => setText16(!text16)}>
            <em><strong>Enhancing conveners' internal capabilities:</strong></em> {text16 && "conveners' coordination capabilities (Dorado et al., 2009; Stadtler and Karakulak, 2020); reducing tensions by generation of short-term value to boost trust among partners (Sonday and Wilson-Prangley, 2018)"}
            </p>
            <p onClick={(e) => setText17(!text17)}>
            <em><strong>Monitoring:</strong></em> {text17 && "aligning the XSP outcomes with expected outcomes (Caldwell et al, 2017; Murphy et al., 2015); hiring altruistic over self-interested individuals (Rivera-Santos et al., 2017); putting input-based and intrinsic incentive mechanisms in place and investing significantly into non-intrusive monitoring (Rivera-Santos et al., 2017); designing an effective business model for monitoring the opportunistic behavior (Villani et al., 2017); increasing XSP efficacy, enforcement, inclusion, procedural fairness, and consensual orientation (Mena and Palazzo, 2012); ad hoc governance structures e.g., accountability forums (Bloomfield, 2006; Reficco and Márquez, 2012; Willems, 2014); embracing accountability (Forrer et al., 2010; Page, 2003; Warsen et al., 2019; West, 2005)"}
            </p>
            <p onClick={(e) => setText18(!text18)}>
            <em><strong>Knowledge organization:</strong></em> { text18 && "minimizing personnel turnover and creating a transparent knowledge language (Caldwell et al, 2017); 'overlapping internal labor market' within the XSPs (Lam, 2007); generation of mutual knowledge (Caldwell et al, 2017) and sharing of that knowledge (Villani et al., 2017); Conveners' facilitation of learning together (Sonday and Wilson-Prangley, 2018)"}
            </p>
          </td>
        </tr>
        <tr>
          <td><strong>How can external tensions be resolved?</strong></td>
          <td>
          <p onClick={(e) => setText19(!text19)}>
            <em><strong>Trust building:</strong></em> {text19 && "conveners can reduce tensions in XSPs by generation of short-term value to boost trust among partners (Sonday and Wilson-Prangley, 2018) and brokering trust between partners and external stakeholders (Baumann- Pauly et al., 2017; Hesse et al., 2019; Trujillo, 2018)"}
            </p>
            <p onClick={(e) => setText20(!text20)}>
            <em><strong>Legitimacy work:</strong></em> {text20 && "increasing XSP external transparency and rule coverage enforcement (Mena and Palazzo, 2012; Reynaers and Grimmelikhuijsen, 2015; Simo and Bies, 2007); gaining positive media coverage (Hesse et al., 2019), avoiding negative events (Rueede and Kreutzer, 2015); removal or marginalization of the source of legitimacy threat (Boghossian and Marques, 2019); issue legitimation (Moore, 2001)"}
            </p>
            <p onClick={(e) => setText21(!text21)}>
            <em><strong>Bridging:</strong></em> {text21 && "use of bridging-agents (Gillett et al., 2019; Trujillo, 2018); forming cultural bridging and engagement with fringe stakeholders (Murphy and Arenas, 2010); designing an XSP authoritative text that resonates with external stakeholders (Koschmann et al., 2012); promoting boundary-spanning discourses (Nicholls and Huybrechts, 2016); increasing social embeddedness (Lashitew et al., 2020); stronger public representation on the boards of nonprofits (Brecher and Wise, 2008)"}
            </p>
            <p onClick={(e) => setText22(!text22)}>
            <em><strong>Conveners' external capabilities:</strong></em> {text22 && "collaborative capabilities of local communities in XSP convening (Cornelius and Wallace, 2010; Cheng, 2019; Smith, 2008)"}
            </p>
            <p onClick={(e) => setText23(!text23)}>
            <em><strong>Managerial external capabilities:</strong></em> {text23 && "stakeholder diversity and expectation management capabilities (Cabral et al, 2019)"}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
  )
}

export default ManagementPhase