import React from 'react'
import Header from '../../components/Header'


function TheoretialPerspectives() {
  return (
    <div>
            <p>Click each <em>subheading</em> to reveal more information.</p>

    <table>
      <tr>
        <th></th>
        <th><strong>Institutional (IN)</strong></th>
        <th><strong>Micro-economic behavior (MEB)</strong></th>
        <th><strong>Capabilities and resources (C&R)</strong></th>
        <th><strong>Managerial and organizational cognition (MOC)</strong></th>
        <th><strong>Stakeholder and social evaluations (SSE)</strong></th>
      </tr>
      <tr>
        <td><strong>Theoretical focus</strong></td>
        <td>Tensions and opportunities associated with institutional complexity</td>
        <td>Transaction and agency costs arising from partners' motivations and contractual arrangements</td>
        <td>Generated, shared, and required capabilities and resources among partners and between partners and external stakeholders</td>
        <td>The overlap or incompatibilities among leaders' and also members' identities, values, and interpretative processes.</td>
        <td>External stakeholders' social evaluation of partners and the partnership </td>
      </tr>
      <tr>
        <td><strong>Theoretical references</strong></td>
        <td>Institutional theory</td>
        <td>Transaction Costs Economics; agency theory</td>
        <td>Resource dependence theory; knowledge-based view; dynamic capabilities</td>
        <td>Identity theory; social and cognitive psychology</td>
        <td>Stakeholder theory; social evaluations theory; social movement literature</td>
      </tr>
      <tr>
        <td><strong>Central concern in XSP Formation phase </strong></td>
        <td>Mitigating potential conflicts between different logics </td>
        <td>Safeguarding against possible partners' opportunism and staffing decisions about employees' motivations</td>
        <td>Maximizing potential opportunities for learning and resource sharing</td>
        <td>Minimizing potential identity threats and value incongruences and choosing the communication approach</td>
        <td>Ensuring the legitimacy of the partners and the partnership</td>
      </tr>
      <tr>
        <td><strong>Central concern in XSP Management phase</strong></td>
        <td>Reconciling logics through combining, prioritizing, oscillating, tolerating, or challenging</td>
        <td>Re-evaluating and, if necessary, renegotiating contract terms and governance modes </td>
        <td>Facilitating the integration and sharing of resources; assessing resource-fit and capability-fit with the partner.</td>
        <td>Designing an efficient identity model and facilitating an open context for members' communication</td>
        <td>Addressing and/or countering sources of legitimacy threat</td>
      </tr>
      <tr>
        <td><strong>Central concern in XSP value dissemination</strong></td>
        <td>Recognition of parties' role in conflict resolution </td>
        <td>Reducing other parties' ex-post opportunistic behavior</td>
        <td>Avoiding the spillover and enabling the storage of knowledge; fair assessment of parties' resource values</td>
        <td>Minimizing identity loss after XSP; recognition of parties' role in identity and discourse management </td>
        <td>Recognition of parties' role in legitimacy or marginalization work </td>
      </tr>
    </table>
    </div>
  )
}

export default TheoretialPerspectives