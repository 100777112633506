import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/about",
      },
    });
  };

  return (
    <Button variant="contained" className="button__login" onClick={handleLogin} style={{marginLeft: "60px"}}>
      Log In
    </Button>
  );
};

export default LoginButton