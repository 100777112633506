import React, { useMemo } from "react"
import { ResponsiveLine } from "@nivo/line"
import { useTheme } from "@mui/material"
import { useGetPapersQuery } from "../state/api"

const OverviewChartPapers = ({ isDashboard = false }) => {
  const theme = useTheme()
  const { data, isLoading } = useGetPapersQuery()
  console.log(
    "🚀 ~ file: OverviewChartPapers.jsx:10 ~ OverviewChartPapers ~ data",
    data
  )

  const totalPapersLine = useMemo(() => {
    if (!data) return {}

    let allDataPoints = {
      id: "totalPapers",
      color: theme.palette.secondary.main,
      data: [],
    }

    const allData = Object.values(data).reduce((allYears, { year }) => {
      const currCount = allYears[year] ?? 0

      return { ...allYears, [year]: currCount + 1 }
    }, {})

    Object.entries(allData).forEach(([year, count]) => {
      if (([year][0] === "undefined") | ([count][0] === undefined)) {
        return
      }
      allDataPoints.data.push({
        x: [year][0],
        y: [count][0],
      })
    })

    return [allDataPoints]
  }, [data]) // eslint-dsable-line react-hooks/exhaustive-deps

  if (!data || isLoading) return "Loading..."

  console.log(
    "🚀 ~ file: OverviewChartPapers.jsx:38 ~ const[totalPapersLine]=useMemo ~ totalPapersLine",
    totalPapersLine
  )

  return (
    <ResponsiveLine
      data={totalPapersLine}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: theme.palette.secondary[200],
            },
          },
          legend: {
            text: {
              fill: theme.palette.secondary[200],
            },
          },
          ticks: {
            line: {
              stroke: theme.palette.secondary[200],
              strokeWidth: 1,
            },
            text: {
              fill: theme.palette.secondary[200],
            },
          },
        },
        legends: {
          text: {
            fill: theme.palette.secondary[200],
          },
        },
        tooltip: {
          container: {
            color: theme.palette.primary.main,
          },
        },
      }}
      margin={{ top: 20, right: 50, bottom: 50, left: 70 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      yFormat=" >-.2f"
      enableArea={isDashboard}
      axisTop={null}
      axisRight={null}
      axisBottom={
        !isDashboard
          ? {
              format: (v) => {
                return ` '${v.slice(v.length - 2, v.length)}`
              },
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: isDashboard ? "" : "Year",
              legendOffset: 36,
              legendPosition: "middle",
            }
          : null
      }
      axisLeft={{
        orient: "left",
        tickValues: 5,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? "" : `Total Papers`,
        legendOffset: -60,
        legendPosition: "middle",
      }}
      enableGridX={false}
      enableGridY={false}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={
        !isDashboard
          ? [
              {
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 30,
                translateY: -40,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]
          : undefined
      }
    />
  )
}

export default OverviewChartPapers
