import React, { useMemo } from "react"
import { ResponsivePie } from "@nivo/pie"
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material"
import { useGetPapersQuery } from "../state/api"

const BreakdownChartPapers = ({ isDashboard = false }) => {
  const { data, isLoading } = useGetPapersQuery()
  const isNonMedium = useMediaQuery("(min-width: 1200px)")

  console.log("🚀 ~ file: BreakdownChart.jsx:8 ~ BreakdownChart ~ data", data)

  const theme = useTheme()

  const colors = [
    theme.palette.secondary[50],
    theme.palette.secondary[100],
    theme.palette.secondary[200],
    theme.palette.secondary[300],
    theme.palette.secondary[400],
    theme.palette.secondary[500],
    theme.palette.secondary[600],
    theme.palette.secondary[700],
    theme.palette.secondary[800],
    theme.palette.secondary[900],
  ]

  const papersByJournal = useMemo(() => {
    if (!data) return {}

    // const names = ["Alice", "Bob", "Tiff", "Bruce", "Alice"]

    // const countedNames = names.reduce((allNames, name) => {
    //   const currCount = allNames[name] ?? 0;
    //   return {
    //     ...allNames,
    //     [name]: currCount + 1,
    //   };
    // }, {});

    const formattedData = Object.values(data).reduce((allJournals, entry) => {
      const { journal } = entry
      const currCount = allJournals[journal] ?? 0
      return {
        ...allJournals,
        [journal]: currCount + 1,
      }
    }, {})
    return Object.entries(formattedData).map(([journal, count], i) => ({
      id: journal,
      label: journal,
      value: count,
      color: colors[i % colors.length],
    }))
  }, [data]) // eslint-dsable-line react-hooks/exhaustive-deps

  if (!data || isLoading) return "Loading..."
  console.log(
    "🚀 ~ file: BreakdownChartPapers.jsx:52 ~ returnformattedData//Object.entries ~ formattedData",
    papersByJournal
  )

  // Object.values(data).forEach((entry) => {
  //   const { journal } = entry
  //   console.log(journal)
  // })

  return (
    <Box
      height={isDashboard ? "400px" : !isNonMedium ? "500px" : "100%"}
      width={undefined}
      minHeight={isDashboard ? "325px" : undefined}
      minWidth={isDashboard ? "325px" : undefined}
      position="relative"
    >
      <ResponsivePie
        data={papersByJournal}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: theme.palette.secondary[200],
              },
            },
            legend: {
              text: {
                fill: theme.palette.secondary[200],
              },
            },
            ticks: {
              line: {
                stroke: theme.palette.secondary[200],
                strokeWidth: 1,
              },
              text: {
                fill: theme.palette.secondary[200],
              },
            },
          },
          legends: {
            text: {
              fill: theme.palette.secondary[200],
            },
          },
          tooltip: {
            container: {
              color: "#fff",
              background: "#000",
            },
          },
        }}
        colors={{ datum: "data.color" }}
        margin={
          isDashboard
            ? { top: 40, right: 40, bottom: 100, left: 40 }
            : !isNonMedium
            ? { top: 40, right: 80, bottom: 100, left: 0 }
            : { top: 40, right: 80, bottom: 80, left: -300 }
        }
        sortByValue={true}
        innerRadius={0.45}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        enableArcLinkLabels={false}
        enableArcLabels={false}
        arcLinkLabelsTextColor={theme.palette.secondary[200]}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 2]],
        }}
        legends={
          !isDashboard && isNonMedium
            ? [
                {
                  anchor: "right",
                  direction: "column",
                  justify: false,
                  translateX: -200,
                  translateY: 126,
                  itemWidth: 73,
                  itemHeight: 35,
                  itemsSpacing: 5,
                  symbolSize: 11,
                  itemDirection: "left-to-right",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000",
                      },
                    },
                  ],
                },
              ]
            : undefined
        }
      />
      <Box
        position="absolute"
        top="50%"
        left="50%"
        color={theme.palette.secondary[400]}
        textAlign="center"
        pointerEvents="none"
        sx={{
          transform: isDashboard
            ? "translate(-75%, -170%)"
            : "translate(-50%, -100%)",
        }}
      ></Box>
    </Box>
  )
}

export default BreakdownChartPapers
