import React, {useState} from 'react'
import { useTheme, Box } from '@mui/material'
import Header from '../../components/Header'
import { Button } from '@mui/material'
import LiteraturePhase from './LiteraturePhase'
import InterdependencyFailures from './InterdependencyFailures'
import FormationPhase from './FormationPhase'
import ManagementPhase from './ManagementPhase'
import ExpansionPhase from './ExpansionPhase'
import TheoretialPerspectives from './TheoretialPerspectives'
import FutureQuestions from './FutureQuestions'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useAuth0 } from '@auth0/auth0-react'
import LoginButton from '../../components/LoginButton'


const InsightsResearch = () => {
    const theme = useTheme()
    const [visible, setVisible] = useState({
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,

    })

    const handleClick = (event) => {
        setVisible({...visible, [event.target.value]: !visible[event.target.value]})
    }

    const {isAuthenticated} = useAuth0()

    // if (!isAuthenticated) {
    //   return (
  
    //               <div className="login-container">
    //               <p>Please login to access the site...</p>
    //               <LoginButton />
    //               </div>
  
  
    //   )
    // }

    return (
        <Box m="1.5rem 2.5rem">
      <Header title="INSIGHTS FOR RESEARCHERS" subtitle="" />
      <Box
        mt="40px"
        height="5vh"
        >
          <div className="insights-holder">
            <div className="insight-button">
          <Button variant="contained" style={{minWidth:'1400px', height:"100px"}} onClick={handleClick} value={0}>An overview of extant XSP literature per phase {visible[0] ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }</Button>
          </div>
          {visible[0] && (
            <div className="insight"><LiteraturePhase /></div>
          )}
          <div className="insight-button">
            <Button variant="contained" style={{minWidth:'1400px', height:"100px"}} onClick={handleClick} value={1}>How does ignoring the interdependencies among theoretical perspectives explain XSPs' failure?{visible[1] ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }</Button>
          </div>
          {visible[1] && (
            <div className="insight"><InterdependencyFailures /></div>
          )}
          <div className="insight-button">
            <Button variant="contained" style={{minWidth:'1400px', height:"100px"}} onClick={handleClick} value={2}>Key questions and findings in past research in XSP Formation Phase {visible[2] ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }</Button>
          </div>
          {visible[2] && (
            <div className="insight">
              <FormationPhase />
            </div>
          )}
          <div className="insight-button">
            <Button variant="contained" style={{minWidth:'1400px', height:"100px"}} onClick={handleClick} value={3}>Key questions and findings in XSP Management Phase {visible[3] ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }</Button>
          </div>
          {visible[3] && (
            <div className="insight"><ManagementPhase /></div>
          )}
          <div className="insight-button">
            <Button variant="contained" style={{minWidth:'1400px', height:"100px"}} onClick={handleClick} value={4}>Key questions and findings in XSP Value Creation, Dissemination and Expansion Phase {visible[4] ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }</Button>
          </div>
          {visible[4] && (
            <div className="insight"><ExpansionPhase /></div>
          )}
          <div className="insight-button">
            <Button variant="contained" style={{minWidth:'1400px', height:"100px"}} onClick={handleClick} value={5}>Different Theoretical perspectives on XSPs {visible[5] ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }</Button>
          </div>
          {visible[5] && (
            <div className="insight">
              <TheoretialPerspectives />
            </div>
          )}
          <div className="insight-button">
            <Button variant="contained" style={{minWidth:'1400px', height:"100px"}} onClick={handleClick} value={6}>Future research questions generated from the interaction XSPs' different theoretical perspectives across phases{visible[6] ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }</Button>
          </div>
          {visible[6] && (
            <div className="insight">
              <FutureQuestions />
            </div>
          )}
          </div>
      </Box>
    </Box>
    )
}

export default InsightsResearch
