import React from 'react'
import Header from '../../components/Header'


function LiteraturePhase() {
  return (
    <div>
        <img style={{width: "90%"}} src="images/literaturePerPhase.png" alt="" />

    </div>
  )
}

export default LiteraturePhase