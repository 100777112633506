import React from 'react'
import Header from '../../components/Header'

function ManagerialThreePhases() {
  return (
    <div>
        <table>
            <tbody>
                <tr>
                    <th></th>
                    <th><em><strong>Formation phase</strong></em></th>
                    <th><em><strong>Management phase</strong></em></th>
                    <th><em><strong>Value creation/dissemination phase</strong></em></th>
                </tr>
                <tr>
                    <td><em><strong>Institutional</strong> perspective's implications</em></td>
                    <td>
                        <p>
                        Managers' awareness and monitoring of key complexities beyond institutional complexity for XSP formation.
                        </p>
                        <p>
                        Managers' assessment of the degree and direction of institutional factors' influence on other factors to efficiently shift and divide scarce managerial attention.
                        </p>
                    </td>
                    <td>
                        <p>
                        Managers can approach the issues of trust, emotions, resource sharing and integration, and stakeholder relationships in XSP by considering methods they use in resolving institutional conflicts.
                        </p>
                        <p>
                        Managers can increase the chances of conflict resolution by understanding how logic resolution approaches can reduce other sources of frictions in XSPs.
                        </p>
                    </td>
                    <td>
                        <p>
                        Managers need to understand factors at the institutional, national, and global levels that can contribute to the value creation and dissemination in XSPs.
                        </p>
                        <p>
                        Managers can choose the institutional environment with attributes that can minimize the frictions of value capture which are driven by opportunism, resource claims, stakeholder pressures, and identity threats.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td><em><strong>Micro-economic behavior</strong> perspective's implications</em></td>
                    <td>
                        <p>
                        Managers' awareness and monitoring of key sources of opportunism beyond partner opportunism for XSP formation.
                        </p>
                        <p>
                        Managers' assessment of the degree and direction of opportunism influence on other key factors to reduce the inaccuracies of cost-benefits in XSP formation.
                        </p>
                    </td>
                    <td>
                        <p>
                        Managers' understanding of the value of trust-building, fairness, and monitoring in issues beyond the relationship with the partner in order to make the XSP management more efficient.
                        </p>
                        <p>
                        Managers' ability to facilitate conflict resolution processes by understanding the mitigating effect of trust-building and monitoring on other sources of frictions in XSPs.
                        </p>
                    </td>
                    <td>
                        <p>
                        Managers' understanding of the importance of developing a governance mindset towards different elements and levels of XSPs can contribute to the value creation and dissemination.
                        </p>
                        <p>
                        Managers' ability to choose the appropriate governance mode to minimize the frictions of value capture driven by logic conflict, resource claims, stakeholder pressures, and identity threats.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td><em><strong>Capabilities and resources</strong> perspective's implications</em></td>
                    <td>
                        <p>
                        Managers' awareness and identification of critical sources of learning and capabilities beyond those of partner before XSP formation.
                        </p>
                        <p>
                        Managers' assessment of the degree and direction of resource complementarity influence on other key factors to reduce the blind reliance on resources in XSP formation.
                        </p>
                    </td>
                    <td>
                        <p>
                        Managers' understanding of the value of capability development within the management phase and in issues beyond the financial resources.
                        </p>
                        <p>
                        Managers' reduction of non-resource relevant conflicts driven by transparent resource sharing and integration processes. 
                        </p>
                    </td>
                    <td>
                        <p>
                        Managers' understanding of the importance of applying spillover, storage, and accrual mechanisms to logics, trust, values & identity, and legitimacy to reach better value outcomes.
                        </p>
                        <p>
                        Managers' ability to choose the optimal level of knowledge sharing, integration, storage, and spillover to minimize the frictions of value capture driven by logic conflict, resource claims, stakeholder pressures, and identity threats.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td><em><strong>Managerial and organizational cognition perspective's implications</strong></em></td>
                    <td>
                        <p>
                        Managers' awareness and identification of critical sources of cognitive biases and communication barriers in external environment.
                        </p>
                        <p>
                        Managers' assessment of the importance of cognitive, interpretive, and communicative elements relative to other key factors to favor objective or subjective criteria for formation.
                        </p>
                    </td>
                    <td>
                        <p>
                        Managers' valuing of flexibility and communication within the management phase and in tandem with all issues.
                        </p>
                        <p>
                        Managers' reduction of time, ambiguity, and hardships in all types of conflict resolution through flexibility, communication, and collaboration. 
                        </p>
                    </td>
                    <td>
                        <p>
                        Managers' ability to contextualize logics, trust, resource sharing, and legitimacy in collaboration to improve value outcomes.
                        </p>
                        <p>
                        Managers' ability to choose the optimal level of openness, flexibility, and collaboration to minimize the frictions of value capture driven by logic conflict, opportunism, resource claims, and stakeholder pressures.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td><em><strong>Stakeholder and social evaluations</strong> perspective's implications</em></td>
                    <td>
                        <p>
                        Managers' awareness and identification of sources of social evaluations and stakeholder pressures in all XSP levels and elements.
                        </p>
                        <p>
                        Managers' assessment of the significance of legitimacy and stakeholder claims relative to other key factors to focus managerial attention to internal vs external linkages.
                        </p>
                    </td>
                    <td>
                    <p>
                        Managers' acknowledgment of the existence of a social evaluation aspect in all conflict sources that can complicate the conflict resolution efforts only focused on non-social aspects of each conflict.
                        </p>
                        <p>
                        Managers' capitalization on the benefits of social approval and legitimation to smooth the resolution of logic, trust, resources, and identity frictions.
                        </p>
                    </td>
                    <td>
                    <p>
                        Managers' ability to contextualize logics, trust, resource sharing, and identify in a social net to improve social value outcomes.
                        </p>
                        <p>
                        Managers' ability to choose the optimal level of conveners' and stakeholders' engagement to minimize value capture frictions driven by logic, trust, resource, and identity conflicts.
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
  )
}

export default ManagerialThreePhases