import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import { configureStore } from "@reduxjs/toolkit"
import globalReducer from "./state"
import { Provider } from "react-redux"
import { setupListeners } from "@reduxjs/toolkit/query"
import { api } from "./state/api"
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from "react-router-dom"
import { HashRouter } from "react-router-dom"
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate"

const store = configureStore({
  reducer: {
    global: globalReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefault) => getDefault().concat(api.middleware),
})
setupListeners(store.dispatch)

const root = ReactDOM.createRoot(document.getElementById("root"))


const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;

root.render(
  <React.StrictMode>      
    <BrowserRouter>
    <Auth0ProviderWithNavigate>

    <Provider store={store}>
      <App />
    </Provider>
    </Auth0ProviderWithNavigate>

    </BrowserRouter>

  </React.StrictMode>
)
