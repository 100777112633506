import React from "react"
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material"
import {
  SettingsOutlined,
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  Groups2Outlined,
  RecieptLongOutlined,
  PublicOutlined,
  QueryStatsOutlined,
  TodayOutlined,
  CalendarMonthOutlined,
  AdminPanelSettingsOutlined,
  trendingUpOutlined,
  PieChartOutlined,
  Dashboard,
  ReceiptLongOutlined,
  TrendingUpOutlined,
  HelpCenterOutlined,
  Article,
} from "@mui/icons-material"
import { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import FlexBetween from "./FlexBetween"
import profileImage from "../assets/profile.jpeg"
import { sizing } from "@mui/system"
import LoginButton from "./LoginButton"
import LogoutButton from "./LogoutButton"
import { useAuth0 } from "@auth0/auth0-react"

const navItems = [
    {
    text: "About",
    icon: <HelpCenterOutlined />,
    label: "Public",
    navText: "about",
  },
  {
    text: "Admin",
    icon: <AdminPanelSettingsOutlined />,
    label: "Private",
    navText: "admin",


  },
  {
    text: "Papers",
    icon: <Article />,
    label: "Public",
    navText: "papers",

  },
  {
    text: "Overview",
    icon: <TrendingUpOutlined />,
    label: "Public",
    navText: "overview",

  },

  {
    text: "Insights for researchers",
    icon: <QueryStatsOutlined />,
    label: "Public",
    navText: "insightsResearchers"
  },
  {
    text: "Insights for practioners & policymakers",
    icon: <QueryStatsOutlined />,
    label: "Public",
    navText: "insightsPractitionersAndPolicymakers"
  },
  {
    text: "Methodology",
    icon: <SettingsOutlined />,
    label: "Public",
    navText: "methodology"
  },

  

]

const Sidebar = ({
  user,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const { pathname } = useLocation()
  const [active, setActive] = useState("")
  const navigate = useNavigate()
  const theme = useTheme()
  const {isAuthenticated} = useAuth0()

  useEffect(() => {
    setActive(pathname.substring(1))
  }, [pathname])

  const publicNavItems = navItems.filter((item) => item.label === "Public")
  const privateNavItems = navItems.filter((item) => item.label === "Private")
  

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSizing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 3rem">
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Typography variant="h5" fontWeight="bold">
                    CROSS-SECTOR
                  </Typography>
                </Box>
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                
              </FlexBetween>
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Typography variant="h5" fontWeight="bold">
                    PAPERS
                  </Typography>
                </Box>
              </FlexBetween>
            </Box>
            <List>
              {publicNavItems.map(({ text, icon, navText }) => {
                if (!icon) {
                  return (
                    <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                      {text}
                    </Typography>
                  )
                }

                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate(`/${navText}`)
                        setActive(navText)
                      }}
                      sx={{
                        backgroundColor:
                          active === navText
                            ? theme.palette.secondary[300]
                            : "transparent",
                        color:
                          active === navText
                            ? theme.palette.primary[600]
                            : theme.palette.secondary[100],
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "2rem",
                          color:
                            active === navText
                              ? theme.palette.primary[600]
                              : theme.palette.secondary[200],
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {active === navText && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )}
                    </ListItemButton>
                  </ListItem>
                )
              })}
              </List>
              {isAuthenticated && (
                            <List>
                            {privateNavItems.map(({ text, icon, navText }) => {
                              if (!icon) {
                                return (
                                  <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                                    {text}
                                  </Typography>
                                )
                              }
            
              
                              return (
                                <ListItem key={text} disablePadding>
                                  <ListItemButton
                                    onClick={() => {
                                      navigate(`/${navText}`)
                                      setActive(navText)
                                    }}
                                    sx={{
                                      backgroundColor:
                                        active === navText
                                          ? theme.palette.secondary[300]
                                          : "transparent",
                                      color:
                                        active === navText
                                          ? theme.palette.primary[600]
                                          : theme.palette.secondary[100],
                                    }}
                                  >
                                    <ListItemIcon
                                      sx={{
                                        ml: "2rem",
                                        color:
                                          active === navText
                                            ? theme.palette.primary[600]
                                            : theme.palette.secondary[200],
                                      }}
                                    >
                                      {icon}
                                    </ListItemIcon>
                                    <ListItemText primary={text} />
                                    {active === navText && (
                                      <ChevronRightOutlined sx={{ ml: "auto" }} />
                                    )}
                                  </ListItemButton>
                                </ListItem>
                              )
                            })}
                            </List>
              )}
                    {!isAuthenticated && (
        <>
          <LoginButton />
        </>
      )}
      {isAuthenticated && (
        <>
          <LogoutButton />
        </>
      )}
          </Box>
        </Drawer>
      )}
    </Box>
  )
}

export default Sidebar
