import React, { useMemo, useState, useEffect } from "react"
import { Box, useTheme } from "@mui/material"
import Header from "../../components/Header"


const Methodology = () => {
  const [showTable, setShowTable] = useState(false)
  const [showList, setShowList] = useState(false)
  const [text1, setText1] = useState(false)
  const [text2, setText2] = useState(false)
  const [text3, setText3] = useState(false)

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="METHODOLOGY" subtitle="Methodological considerations for our Systematic Review" />
      <p>Click the <em>subheadings</em> to reveal more information.</p>
      <p onClick={(e) => setText1(!text1)}><strong>The main database to retrieve articles</strong>:</p>
      {text1 && (<p> <em>Business Source Complete</em></p>)}
      <p onClick={(e) => setShowTable(!showTable)}><strong>Keywords to extract articles: </strong></p>
      {showTable && (
      <table>
        <tbody>
          <tr>
          <td>Public - private </td>
          <td rowSpan={8}> + [collaboration(s), partnership(s), tie(s), initiative(s), collaborating, coalition(s), cooperation(s)]</td>
          </tr>
          <tr>
            <td>Public - [Third-party or Third-sector]</td>
          </tr>
          <tr>
            <td>Private - [Third-party or Third-sector]</td>
          </tr>
          <tr>
            <td>Public - [Not-for-profit or nonprofit or NFP]</td>
          </tr>
          <tr>
            <td>Private - [Not-for-profit or nonprofit or NFP]</td>
          </tr>
          <tr>
            <td>Multi-party or multi-stakeholder or multi-sector or multi-sectoral</td>
          </tr>
          <tr>
            <td>Cross-sector or cross-sectoral or between sector or sectoral </td>
          </tr>
          <tr>
            <td>XS </td>
          </tr>
          <tr>
            <td>Collaboration or partnership(s)</td>
            <td rowSpan={2}>Collaborating or partnering</td>
          </tr>
          <tr>
            <td>Collaborating or partnering</td>
          </tr>
          <tr>
            <td colSpan={2}>XSP(s), PPP(s)</td>
          </tr>
        </tbody>
      </table>
      )}
      <p onClick={(e) => setText2(!text2)}><strong>Articles' time frame:</strong></p>
      {text2 && (<p> 1980 to 2021.</p>)}
      <p onClick={(e) => setShowList(!showList)} >
        <strong>Journal inclusion and exclusion criteria:</strong>
        </p>
        {showList && (
        <ul>
          <li>
          1) Based on the Academic Journal Guide (2018) by Chartered ABS, 4* and 4 outlets in Ethics-CSR-Management, Entrepreneurship and small business, International Business, Innovation, Organization Studies, Strategy, and Public sector categories (23 outlets); 
          </li>
          <li>
          2) two additional outlets from FT 50 list; 
          </li>
          <li>
          3) two journals in which initial XSP research was published;
          </li>
          <li>
          4) excluded journals with  practical orientation (a total of 27 outlets).
          </li>
        </ul> 
        )}
      <p onClick={(e) => setText3(!text3)}>
      <strong>Initial and final sample:</strong>
      </p>
      {text3 && (
      <p> 561 and 296 publications (excluded those works in which the analysis of XSPs was not central, those that provide a descriptive account of XSPs or discuss specific cases of XSPs without theorizing about relevant causal relationships
      </p>
        )}
      </Box>
  )
}

export default Methodology
