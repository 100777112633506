import React, {useState} from 'react'
import Header from '../../components/Header'


function FormationPhase() {
  const [text1, setText1] = useState(false)
  const [text2, setText2] = useState(false)
  const [text3, setText3] = useState(false)
  const [text4, setText4] = useState(false)
  const [text5, setText5] = useState(false)
  const [text6, setText6] = useState(false)
  const [text7, setText7] = useState(false)
  const [text8, setText8] = useState(false)
  const [text9, setText9] = useState(false)
  const [text10, setText10] = useState(false)

  return (
    <div>
            <p>Click each <em>subheading</em> to reveal more information.</p>

    <table>
      <tbody>
        <tr>
          <th>
          <strong>Key questions</strong>
          </th>
          <th>
          <strong>Core findings</strong>
          </th>
        </tr>
        <tr>
          <td><strong>What factors are considered in the formation of XSPs?</strong></td>
          <td>
          <p onClick={(e) => setText1(!text1)}>
            <em><strong>Institutional and market conditions:</strong></em> {text1 && "political  and market uncertainty (Gutie´rrez et al., 2016; Kivleniece and Quelin, 2012; Simo and Bies, 2007); institutional pluralism (Coston, 1998); attractiveness of the external beneficiaries’ population based on e.g., race, level of poverty, and size (Garrow, 2014; Lazzarini, 2020; Neal, 2008); intermediaries’ support (e.g., policy making decisions to make the institutional arrangements more favorable) for the formation of such partnerships (Busom and Fernandez-Ribas, 2008; Lamine, Anderson, Jack, and Fayolle, 2021; Manning and Roessler, 2014; Peterman, Kourula, and Levitt, 2014); deficient market and legal institutions (Bryson, Crosby, and Stone, 2015; Zhou, 2017); market-based or policy-based institutional orientation and institutional coherence (Vurro et al., 2010); globalization and entry barriers in international markets (Lindenberg and Dobel, 1999; Xing, Liu, and Lattermann, 2020); institutions’ level of development and support for XSPs  (Chen et al., 2022; Fransen and Burgoon, 2014; Hood at el., 1993); existence of structural holes in the market (Yang and Cheong, 2018)"}
            </p>
            <p onClick={(e) => setText2(!text2)}>
            <em><strong>Partners' needs:</strong></em> {text2 && "minimizing costs (Kivleniece and Quelin, 2012; Lazzarini, 2020; Zafra-Gomez et al., 2013); sharing risk (Crosby and Bryson, 2006); increasing bargaining power (Chatain and Plaksenkova, 2018); need for the coordination of public services (Osborne, 1997); gaining market externalities and reducing information asymmetry (Luo and Kaul, 2019); accessing better and complementary resources (Physical, Organizational, and Human capital), reputation and status (Clarke and MacDonald, 2019; den Hond et al., 2005; Di Domenico et al., 2009; Gazley and Brudney, 200; Greenwood and Empson, 2003; Kalesnikaite and Neshkova, 2020; Ordonez-Ponce et al., 2021; Zeimers et eal., 2019); increasing investment-levels, and capabilities (Chatain and Plaksenkova, 2018; Guo and Acar, 2005; Kivleniece and Quelin, 2012); less government involvement (Blair, 2002); level of competition for resource (Bouchard and Raufflet, 2019)"}
            </p>
            <p onClick={(e) => setText3(!text3)}>
            <em><strong>Partners' values:</strong></em>  {text3 && "organizational mission and political ideology (Gazley, 2010; Seitanidi et al., 2010); value priorities intrinsic to the corporation and the social enterprise (Di Domenico et al., 2009); political ties influence on choosing affiliated NGOs vs independent NGOs as XSP partner (Chen et al., 2022); service religiosity (Fu et al., 2021)"}
            </p>
            <p onClick={(e) => setText4(!text4)}>
            <em><strong>Managers' characteristics:</strong></em> {text4 && "managers' expertise and prior XSP experience (Bruce et al, 2019; Longoria, 1999; Reast et al., 2010); gender of partners’ managers (AbouAssi et al., 2019; Gazley, 2010); partners’ discourses of managerialism, consociationalism and participation (Skelcher et al., 2005), executives’ attitudes towards XSPs (Gazley, 2010); staff religiosity (Fu et al., 2021)"}
            </p>
            <p onClick={(e) => setText5(!text5)}>
            <em><strong>Partners' strengths:</strong></em> {text5 && "history of interactions and level of developed trust (Ansell and Gash, 2008; den Hond et al., 2005; Fransen and Burgoon, 2014; Seitanidi et al., 2010); social embeddedness, network closure, and centrality (AbouAssi et al., 2021;Lashitew et al., 2020; O’Brien et al., 2019; Puyvelde and Peter Raeymaeckers, 2020; Shwom, 2015); availability of public knowledge–based resources and private political resources (Bouchard and Raufflet, 2019; Kivleniece and Quelin, 2012); partners’ CSR commitments (den Hond et al., 2005); government ability to block policies and generate tax revenue (Bertelli, 2019); provision of financial incentives (e.g., prospects for return on investments, cross-subsidizing of profitable and unprofitable project, reducing risks) (Koppenjan and Enserink, 2009); high stock of symbolic capital (Bocquet et al., 2020), partners’ age and scope (AbouAssi et al., 2021)"}
            </p>
            <p onClick={(e) => setText6(!text6)}>
            <em><strong>Partnership characteristics:</strong></em> {text6 && "partners' levels of investment (Simpson et al., 2011); power relationships between potential partners (Coston, 1998)"}
            </p>
            <p onClick={(e) => setText7(!text7)}>
            <em><strong>Social evaluations:</strong></em> {text7 && "Private–Nonprofit relationships’ level of social appropriateness (Simpson et al., 2011; Zeimers et eal., 2019); partner’ level of social trust (Chen et al., 2022); pressure exerted by NGOs and consumers (den Hond et al., 2005); societal pressures such as negative media attention (Fransen and Burgoon, 2014; Reficco and Márquez, 2012)"}
            </p>
          </td>
        </tr>
        <tr>
          <td><strong>What factors are compromised or ignored in the formation of XSPs?</strong></td>
          <td>
          <p onClick={(e) => setText8(!text8)}>
            <em><strong>Structural and operational:</strong></em> {text8 && "a comprehensive approach towards partnership configuration and learning strategies (van Tulder and Keen, 2018); ethics of XSPs (Ghere, 1996); undermining of contractual setting (Lenferink et al., 2013; Reeves,  2008); inefficient and ambiguous partner selection process (Marques and Berg, 2011; Ruben and Schulpen, 2009)"}
            </p>
            <p onClick={(e) => setText9(!text9)}>
            <em><strong>Problem oriented:</strong></em> {text9 && "knowledge uncertainty understanding the severity and complexity of issues (Dentoni et al., 2018; Hood at el., 1993); project-specific complexities (Lenferink et al., 2013)"}
            </p>
            <p onClick={(e) => setText10(!text10)}>
            <em><strong>Conflicts:</strong></em> {text10 && "comprehensive consideration of conflicts (van Tulder and Keen, 2018); Decision makers’ tendency to form partnerships based on common grounds and points of convergence and not based on conflicts (Bryson et al., 2006; Nicholls and Huybrechts, 2016)."}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
  )
}

export default FormationPhase