import { CssBaseline, ThemeProvider } from "@mui/material"
import { createTheme } from "@mui/material/styles" //might have to remove styles, or {} around createTheme
import { themeSettings } from "./theme"
import { useSelector } from "react-redux"
import { useMemo } from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import Layout from "./scenes/layout"
import Dashboard from "./scenes/dashboard"
import Products from "./scenes/products"
import Customers from "./scenes/customers"
import Transactions from "./scenes/transactions"
import Geography from "./scenes/geography"
import Overview from "./scenes/overview"
import Daily from "./scenes/daily"
import Monthly from "./scenes/monthly"
import Breakdown from "./scenes/breakdown"
import Admin from "./scenes/admin"
import Performance from "./scenes/performance"
import Papers from "./scenes/papers"
import About from "./scenes/about"
import InsightsResearch from "./scenes/insightsResearch"
import Callback from "./scenes/callback"
import { useAuth0 } from "@auth0/auth0-react"
import { AuthenticationGuard } from "./components/AuthenticationGuard"
import InsightsPractAndPol from "./scenes/insightsPracticionerAndPolicy"
import Methodology from "./scenes/methodology"
import "./App.css"
import LoginButton from "./components/LoginButton"

function App() {
  // const {isLoading, isAuthenticated } = useAuth0()
  const mode = useSelector((state) => state.global.mode)
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]) //necessary for material ui. CssBaseline resets the css to defualt css

  //layout component is parent of every element. Includes navbar and side bar
  // if (isLoading) {
  //   return "Loading..."
  // }

    // if (!isAuthenticated) {
  //   return (

                // <div className="login-container">
                // <p>Please login to access the site...</p>
                // <LoginButton />
                // </div>


  //   )
  // }

  const { isAuthenticated } = useAuth0()

  return (
    <div className="app">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {isAuthenticated && (

         
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<Navigate to="/about" replace />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/products" element={<Products />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/geography" element={<Geography />} />
              <Route path="/overview" element={<Overview />} />
              <Route path="/insightsResearchers" element={<InsightsResearch />} />
              <Route path="/insightsPractitionersAndPolicymakers" element={<InsightsPractAndPol />} />
              <Route path="/monthly" element={<Monthly />} />
              <Route path="/breakdown" element={<Breakdown />} />
              <Route path="/about" element={<About />} />
              <Route path="/performance" element={<Performance />} />
              <Route path="/papers" element={<Papers />} />
              <Route path="/admin" element={<AuthenticationGuard component={Admin} />} />
              <Route path="/callback" element={<Callback />} />
              <Route path="/methodology" element={<Methodology />} />
              
            </Route>
          </Routes>
           )}
           {!isAuthenticated && (
              <div className="login-container">
              <p>Please login to access the site...</p>
              <LoginButton />
              </div>
            
           )}
        </ThemeProvider>
    </div>
  )
}

export default App
