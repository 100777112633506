import React from "react"
import { Box, useTheme } from "@mui/material"
import Header from "../../components/Header"

const Callback = () => {

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="CALLBACK" subtitle="call back debug..." />
    </Box>
  )
}

export default Callback
