import React, {useState, useEffect} from "react"
import { useTheme, Box } from "@mui/material"
import Header from "../../components/Header"
import { useGetPapersQuery } from "../../state/api"
import { DataGrid } from "@mui/x-data-grid"
import BasicTable from "../../components/BasicTable"
import Select from "react-select"
import { Button } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useAuth0 } from "@auth0/auth0-react"
import LoginButton from "../../components/LoginButton"


const filterData = (filters) => (data) => {
  return data.filter((row) => {
    for (let column in filters) {
      if (!filters[column].includes(row[column])) {
        return false
      }
    }
    return true
  })
}


const Papers = () => {
  const theme = useTheme()
  const [filters, setFilters] = useState({})
  const { data, isLoading } = useGetPapersQuery()
  const [filteredData, setFilteredData] = useState(data)
  const [showFilters, setShowFilters] = useState(false)
  const {isAuthenticated } = useAuth0()



  useEffect(() => {
    if (!data) {
      return
    }
    setFilteredData(filterData(filters)(data))
  }, [filters, data])

  const handleFilterChange = (column, selectedOptions) => {
    setFilters({
      ...filters, 
      [column]: selectedOptions ? selectedOptions.map(option => option.value) : []
    })
  }

  const updateFilter = (column, selectedValues) => {
    if (selectedValues.includes("All") || !(selectedValues.length > 0) ) {
      const newFilters = {...filters}
      delete newFilters[column]
      setFilters(newFilters)
    } else {
      setFilters({
        ...filters,
        [column]: selectedValues
      })
    }
  }

  const handleClick = (event) => {
    setShowFilters(!showFilters)
  }

  console.log("🚀 ~ file: index.jsx:9 ~ Papers ~ data", data)
  if (!data || isLoading || data.length < 1) {
    return <>Loading...</>
  }

  const uniqueColumnValues = {}
  Object.keys(data[0]).forEach((column) => {
    uniqueColumnValues[column] = [...new Set(data.map((row) => row[column]))]
  })

  const exclude = ["__v", "createdAt", "updatedAt", "_id"]
  const excludeFilter = ["Article Title", 'Article Link']
  const cols = Object.keys(data[0]).reduce((accumulator, key) => {
    if (exclude.includes(key)) {
      return accumulator
    }

    if (key === "Article Link") {
      return [...accumulator, {
        Header: key,
        accessor: key,
        Cell: ({value}) => (
          <a href={value} target="_blank">{value}</a>
        )
      }]
    }
  
    return [...accumulator, {
      Header: key,
      accessor: key
    }]
  }, []
  )

  const filterCols = Object.keys(data[0]).reduce((accumulator, key) => {
    if (exclude.includes(key) || excludeFilter.includes(key)) {
      return accumulator
    }
    return [...accumulator, key]
  }, [])

  // const columns = [
  //   {
  //     field: "title",
  //     headerName: "Title",
  //     flex: 1.5,
  //   },
  //   {
  //     field: "journal",
  //     headerName: "Journal",
  //     flex: 1,
  //   },
  //   {
  //     field: "year",
  //     headerName: "Publication Year",
  //     flex: 0.5,
  //   },
  //   {
  //     field: "authors",
  //     headerName: "Authors",
  //     flex: 1,
  //   },
  //   {
  //     field: "methods",
  //     headerName: "Research Methods",
  //     flex: 0.4,
  //   },
  //   {
  //     field: "geoLocation",
  //     headerName: "Geo Location of Sample",
  //     flex: 0.5,
  //   },
  //   {
  //     field: "collaborationType",
  //     headerName: "Type of Collaboration",
  //     flex: 0.5,
  //   },
  //   {
  //     field: "issue",
  //     headerName: "Issue Addressed",
  //     flex: 1,
  //   },
  //   {
  //     field: "aboutLegitimacy",
  //     headerName: "Aout Legitimacy of Partnership or Partners?",
  //     flex: 0.4,
  //   },
  //   {
  //     field: "studyFocus",
  //     headerName: "Focus on Individuals?",
  //     flex: 0.4,
  //   },
  //   {
  //     field: "aboutNetwork",
  //     headerName: "Is it about Network?",
  //     flex: 0.4,
  //   },
  //   {
  //     field: "studyPeriod",
  //     headerName: "Period of data collection",
  //     flex: 0.5,
  //   },
  //   {
  //     field: "numberOfPartnerships",
  //     headerName: "Number of Partnerships",
  //     flex: 0.5,
  //   },
  //   {
  //     field: "numberOfOrganizations",
  //     headerName: "Number of Organisations",
  //     flex: 0.5,
  //   },
  //   {
  //     field: "numberOfCountries",
  //     headerName: "Number of Countries",
  //     flex: 0.5,
  //   },
  //   {
  //     field: "database",
  //     headerName: "Databses Used",
  //     flex: 1,
  //   },
  // ]

  // if (!isAuthenticated) {
  //   return (

  //               <div className="login-container">
  //               <p>Please login to access the site...</p>
  //               <LoginButton />
  //               </div>


  //   )
  // }
  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="CROSS SECTOR PAPERS"
        subtitle="Explore information for all of the available papers"
      />
      <Box 
       height="75vh"
        mt="40px"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <Button variant="contained" style={{minWidth:'100px'}} onClick={handleClick} value={0}>Show filters {showFilters ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> } </Button>
        {showFilters && (
           <div>
           {filterCols.map((column, index) => (
             <>
             <p key={index}>{column} filter:</p>
             <Select
               isMulti
               key={index}
               onChange={(selectedOptions) => {
                 const selectedValues = selectedOptions.map(option => option.value)
                 updateFilter(column, selectedValues)}}
               options={[{value: 'All', label:'All'}, ...uniqueColumnValues[column].map((value) => ({value, label: value}))]} />
               </>
           ))}
           </div>
        )}
       
        {cols && filteredData && (
          <div className="table-holder">
              <BasicTable columns={cols} data={filteredData} />
          </div>

        )}
        {/* <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={data || {}}
          columns={columns}
        /> */}
      </Box>
    </Box>
  )
}

export default Papers
