import React from 'react'
import Header from '../../components/Header'


function InterdependencyFailures() {
  return (
    <div>
        <img style={{width: "90%"}} src="images/InterdepencyFailureDiagram.png" alt="" />

    </div>
  )
}

export default InterdependencyFailures