import React, { useMemo, useState, useEffect } from "react"
import { Box, useTheme } from "@mui/material"
import Header from "../../components/Header"
import { useAuth0 } from "@auth0/auth0-react"
import LoginButton from "../../components/LoginButton"


const About = () => {
  const {isAuthenticated} = useAuth0()

  // if (!isAuthenticated) {
  //   return (

  //               <div className="login-container">
  //               <p>Please login to access the site...</p>
  //               <LoginButton />
  //               </div>


  //   )
  // }

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="ABOUT" subtitle="" />
      <p>
      In this website, we present insights from a systematic review of the literature on cross-sector collaborations by Angela Aristidou (UCL School of Management) and Mohamad Sadri (ESSEC Business School).
      </p>
      <p>
      Our world is confronted with complex societal, environmental, and economical challenges, such as poverty, education, climate change, peace, and gender inequality. Termed as 'grand challenges', these surpass the capacities of private, public or third sectors to address in isolation. In response, there has been a growing recognition among researchers, practitioners, and policymakers of the pivotal role played by partnerships that bring together public, private, and non-profit sectors to address these challenges. Cross-sector partnerships (XSPs) are voluntary collaborative agreements among organizations from two or more different sectors, wherein they engage in intentional exchange and the co-development of resources and cooperatively attempt to address issues identified by the public policy agenda and emerging sustainability concerns.
      </p>
      <p>
      Our comprehensive review of research <a href="methodology" target="_blank">(Methodology tab)</a> on XSPs serves as the foundation both to address fundamental yet open questions on XSP process, purpose and impact, and to examine the untapped potential of cross-pollinating theoretical perspectives on XSPs. Overall, our analysis, derived from 296 empirical (quantitative and qualitative) and conceptual papers <a href="papers" target="_blank">(Article dashboard tab)</a>, indicates while there are some similarities in terms of identifying XSP phases and their characteristics, as well as salient theoretical perspectives on XSPs, our review significantly departs from these works by providing an organized theoretical topology of XSP phases and by focusing on how other perspectives are enriched by a focal theoretical perspective – whether through theoretical informing or interacting at the theoretical interstice <a href="insightsResearchers" target="_blank">(Insights for researchers tab)</a>. Our work also delivers insights for managers and into current and pressing policy debates concerning longstanding and newly-emerging issues that may affect XSP purpose and impact <a href="insightsPractitionersAndPolicymakers" target="_blank">(Insights for managers and policymakers tab)</a>.
      </p>

      <div className="bio-holder">    
        <img className="profile-image-A" src="images/AristidouAngela.jpg" alt="" />
        <div className="bio-text">
          <p>Professor <strong>Angela Aristidou</strong> speaks, writes and consults about the implementation of digital and artificial intelligence tools in health services. Angela specialises in strategy and entrepreneurship at University College London's School of Management and she is a Digital Fellow (Faculty Affiliate) at Stanford University's Digital Economy Lab, in the Human-centred AI Centre. 
</p>
<p>Angela is an international award-winning academic (among other: Fulbright; Stanford University's CASBS <a href="https://casbs.stanford.edu/people/angela-aristidou">https://casbs.stanford.edu/people/angela-aristidou</a>), she is solo grant-holder for a UK Research Innovation Future Leader Fellowship (approx. £1.7 UK million pounds; 2020-2028) and she currently leads a team of researchers examining digital care innovations in the UK, USA, China and Canada. Angela is an expert in how private tech companies, governments and public sector organisations, nonprofits and communities collaborate to innovate in healthcare. </p>
<p>She is an alumna of Harvard University and the University of Cambridge, and a Fellow of the Royal Society of the Arts.</p>
          <p>
          More information about Angela can be found on:
          </p>
          <p>
          <a href="https://linkedin.com/in/angela-a-22a3318a" target="_blank">linkedin.com/in/angela-a-22a3318a</a> // @ a.aristidou[at]ucl.ac.uk
          </p>
        </div>
      </div>
      <div className="bio-holder">
        <img className="profile-image-M" src="images/SadriMohamad.jpg" alt="" />
        <div className="bio-text">
          <p>
          <strong>Mohamad Sadri</strong> is an Assistant Professor of Management at ESSEC Business School and a Senior Honorary Research Fellow at UCL School of Management. He received my Ph.D. in Strategy from IE Business School and was a postdoctoral researcher at UCL for a year. His research lies at the intersection of strategy and organization theory. He focuses on the interactions among firms and stakeholders to understand how stakeholders' pressures in societal issues are shaped and how firms respond to such pressures. His work has been published in leading management journals such as Strategic Management Journal and Journal of Management.
          </p>
          <p>
          More information about Mohamad can be found on:
          </p>
          <p>
          <a href="http://www.mohamadsadri.com" target="_blank">mohamadsadri.com</a> // <a href="https://www.twitter.com/Mohamad__sadri " target="_blank">twitter.com/Mohamad__sadri </a>// <a href="https://linkedin.com/in/mohamad-sadri" target="_blank">linkedin.com/in/mohamad-sadri/</a> // @ mohamad.sadri[at]essec.edu
          </p>
        </div>
      </div>

      <h1>FUNDING</h1>
      <p>This website and the work underpinning it have been funded through the UK Research Innovation grant Innovating Across Sectors (MR/S033009/1; PI Prof. Angela Aristidou). The opinions and insights on this site are not a reflection of UKRI's views but of the authors.</p>

      <br />
      <br />
      <p style={{fontSize: "12pt"}}> <em>Website designed and developed by Seth Fletcher // s.fletcher.17@ucl.ac.uk</em> </p>
    
    </Box>
  )
}

export default About
