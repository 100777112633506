import React from "react"
import { Box } from "@mui/material"
import Header from "../../components/Header"
import BreakdownChartPapers from "../../components/BreakdownChartPapers"

const Breakdown = () => {
  return (
    <Box m="1.5rem 2.rem">
      <Header
        title="BREAKDOWN"
        subtitle="Breakdown of number of papers by journal"
      />
      <Box mt="40px" height="75vh">
        <BreakdownChartPapers />
      </Box>
    </Box>
  )
}

export default Breakdown
