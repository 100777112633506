import React, { useState, useEffect } from "react"
import { Box, Button } from "@mui/material"
import Header from "../../components/Header"
import OverviewChart from "../../components/OverviewChart"
import OverviewChartPapers from "../../components/OverviewChartPapers"
import { useGetPapersQuery } from "../../state/api"
import LineGraph from "../../components/LineGraph"
import { Bar, Line } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import BasicTable from "../../components/BasicTable"
import { useAuth0 } from "@auth0/auth0-react"
import LoginButton from "../../components/LoginButton"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


const countEntriesByJournal = (data) => {
  const counts = {}

  for (const row of data) {
    if (row['Journal'] in counts) {
      counts[row['Journal']] ++
    } else {
      counts[row['Journal']] = 1
    }
  }

  return counts
}

const getLineGraphData = (data) => {
  console.log(data)
  const groupedYearData = data.reduce((acc, curr) => {
    acc[curr["Year"]] = (acc[curr["Year"]] || 0) + 1
    return acc
  }, {})

  console.log(groupedYearData)

  const labels = Object.keys(groupedYearData).filter((key) => key !== "").sort()
  console.log(labels)
  const counts = labels.map(label => groupedYearData[label])
  console.log(counts)

  return{
    labels,
    dataset: [
      {
        label: "Publications by Year",
        data: counts,
        fill: false,
        backgroundColor: 'rgb(75, 192, 192',
        borderColor: 'rgba(75, 192, 192, 0.2'
      }
    ]
  }

}

const Overview = () => {
  // const [view, setView] = useState("units")
  const { data, isLoading } = useGetPapersQuery()
  const {isAuthenticated} = useAuth0()

  console.log("🚀 ~ file: index.jsx:27 ~ Overview ~ data:", data)
  const [filteredData, setFilteredData] = useState(undefined)

  // useEffect(() => {
  //   if (!data) {
  //     return
  //   }
  //   setFilteredData(data)
  // }, [data])
  
  if (!data || isLoading || !(data.length > 0) ) {
    return <>Loading...</>
  }



  const journalCounts = countEntriesByJournal(data)
  const chartData = {
    labels: Object.keys(journalCounts),
    datasets: [
      {
        label: "Journal Entries",
        data: Object.values(journalCounts),
        backgroundColor: '#ffd166',
      }
    ]
  }

  const handleBarClick = (e, elements) => {
    if (elements.length > 0) {
      const index = elements[0].index
      const clickedJournal = chartData.labels[index]
      const newFilteredData = data.filter(item => item["Journal"] === clickedJournal)
      setFilteredData(newFilteredData)
    }
  }

  const exclude = ["__v", "createdAt", "updatedAt", "_id"]
  const cols = Object.keys(data[0]).reduce((accumulator, key) => {
    if (exclude.includes(key)) {
      return accumulator
    }

    if (key === "Article Link") {
      return [...accumulator, {
        Header: key,
        accessor: key,
        Cell: ({value}) => (
          <a href={value} target="_blank">{value}</a>
        )
      }]
    }
  
    return [...accumulator, {
      Header: key,
      accessor: key
    }]
  }, []
  )

  // const lineData = getLineGraphData(filteredData || data)

  // if (!isAuthenticated) {
  //   return (

  //               <div className="login-container">
  //               <p>Please login to access the site...</p>
  //               <LoginButton />
  //               </div>


  //   )
  // }


  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="OVERVIEW"
        subtitle="Break down of the publications across journals and years."
      />
        <p>Click on any bar in the graph to filter the data for that journal. Click the <em>Clear Filters</em> button to show all of the data</p>
        <Button variant="contained" onClick={(e) => setFilteredData(undefined)}>Clear Filters</Button>

      <div className="chart-holder">
        <div className="chart">
      <Bar
        data={chartData}
        options={{
          indexAxis: 'y',
          scales: {
            x: {
              beginAtZero: true,
              ticks: {
                font: {
                  size: 20
                },
              }
            },
            y: {
              ticks: {
                font: {
                  size: 20
                }
              }
            }
          },
          plugins: {
            legend: {
              display: false
            },

            title: {
              display: true,
              text: 'Paper Breakdown by Journal',
              font: {
                size: 30
              }
            }
          },
          maintainAspectRatio: false,
          onClick: handleBarClick,
          responsive: false
        }}
        height={1000}
        width={800}
      />
      </div>
      <div className="chart">
        <LineGraph  data={filteredData || data}/>
      </div>
      </div>

      {filteredData !==undefined && filteredData.length > 0 && (
              <div className="table-holder">
          <BasicTable columns={cols} data={filteredData}/>
          </div>

      )}
        {/* <FormControl sx={{ mt: "1rem" }}>
          <InputLabel>View</InputLabel>
          <Select
            value={view}
            label="View"
            onChange={(e) => setView(e.target.value)}
          >
            <MenuItem value="sales">Sales</MenuItem>
            <MenuItem value="units">Unites</MenuItem>
          </Select>
        </FormControl> */}
        {/* <OverviewChartPapers /> */}
    </Box>
  )
}

export default Overview
