import React, {useState} from 'react'
import Header from '../../components/Header'


function FutureQuestions() {
  const [text1, setText1] = useState(false)
  const [text2, setText2] = useState(false)
  const [text3, setText3] = useState(false)
  const [text4, setText4] = useState(false)
  const [text5, setText5] = useState(false)
  const [text6, setText6] = useState(false)
  const [text7, setText7] = useState(false)
  const [text8, setText8] = useState(false)
  const [text9, setText9] = useState(false)
  const [text10, setText10] = useState(false)
  const [text11, setText11] = useState(false)
  const [text12, setText12] = useState(false)
  const [text13, setText13] = useState(false)
  const [text14, setText14] = useState(false)
  const [text15, setText15] = useState(false)
  const [text16, setText16] = useState(false)
  const [text17, setText17] = useState(false)
  const [text18, setText18] = useState(false)
  const [text19, setText19] = useState(false)
  const [text20, setText20] = useState(false)
  const [text21, setText21] = useState(false)
  const [text22, setText22] = useState(false)
  const [text23, setText23] = useState(false)
  const [text24, setText24] = useState(false)
  const [text25, setText25] = useState(false)
  const [text26, setText26] = useState(false)
  const [text27, setText27] = useState(false)
  const [text28, setText28] = useState(false)
  const [text29, setText29] = useState(false)
  const [text30, setText30] = useState(false)
  const [text31, setText31] = useState(false)
  const [text32, setText32] = useState(false)
  const [text33, setText33] = useState(false)
  const [text34, setText34] = useState(false)
  const [text35, setText35] = useState(false)
  const [text36, setText36] = useState(false)
  const [text37, setText37] = useState(false)
  const [text38, setText38] = useState(false)
  const [text39, setText39] = useState(false)
  const [text40, setText40] = useState(false)
  const [text41, setText41] = useState(false)
  const [text42, setText42] = useState(false)
  const [text43, setText43] = useState(false)
  const [text44, setText44] = useState(false)
  const [text45, setText45] = useState(false)
  const [text46, setText46] = useState(false)
  const [text47, setText47] = useState(false)
  const [text48, setText48] = useState(false)
  const [text49, setText49] = useState(false)
  const [text50, setText50] = useState(false)
  const [text51, setText51] = useState(false)
  const [text52, setText52] = useState(false)
  const [text53, setText53] = useState(false)
  const [text54, setText54] = useState(false)
  const [text55, setText55] = useState(false)
  const [text56, setText56] = useState(false)
  const [text57, setText57] = useState(false)
  const [text58, setText58] = useState(false)
  const [text59, setText59] = useState(false)
  const [text60, setText60] = useState(false)


  return (
    <div>
            <p>Click each <em>subheading</em> to reveal more information.</p>

    <table>
      <tbody>
        <tr>
          <th></th>
          <th><strong><em>Formation phase</em></strong></th>
          <th><strong><em>Management phase</em></strong></th>
          <th><strong><em>Value creation/dissemination phase</em></strong></th>
        </tr>
        <tr>
          <td><em><strong>Institutional perspective</strong> interacting with other perspectives</em></td>
          <td>
          <p onClick={(e) => setText1(!text1)}>
            <em>MEB:</em> {text1 && "How do conflicting logics affect opportunistic behavior by parties?"}
            </p>
            <p onClick={(e) => setText2(!text2)}>
            <em>C&R:</em> {text2 && "How does institutional complexity affect the formation of XSPs driven by resource complementarity?"}
            </p>
            <p onClick={(e) => setText3(!text3)}>
            <em>MOC:</em> {text3 && "How do shifts in institutional regimes affect cognition, emotion and value considerations for the formation of XSPs?"}
            </p>
            <p onClick={(e) => setText4(!text4)}>
            <em>SSE:</em> {text4 && "How do institutional arrangements influence the salience of social evaluations of partners and the partnership before XSP formation?"}
            </p>
          </td>
          <td>
            <p onClick={(e) => setText5(!text5)}>
            <em>MEB:</em> {text5 && "How do efforts in reconciling conflicting logics influence the perceptions of opportunism in partners?"}
            </p>
            <p onClick={(e) => setText6(!text6)}>
            <em>C&R:</em> {text6 && "How does tolerating or challenging conflicting logics can facilitate or constrain knowledge sharing and integration?"}
            </p>
            <p onClick={(e) => setText7(!text7)}>
            <em>MOC:</em> {text7 && "Which methods of logic conflict resolution do more strongly threaten the professional identities of employees within XSPs?"}
            </p>
            <p onClick={(e) => setText8(!text8)}>
            <em>SSE:</em> {text8 && "When do logic conflict resolution efforts positively influence third-party legitimacy evaluations of partners and partnership?"}
            </p>
          </td>
          <td>
            <p onClick={(e) => setText9(!text9)}>
            <em>MEB:</em> {text9 && "What institutional factors can impede partners' opportunistic distribution of value in XSPs?"}
            </p>
            <p onClick={(e) => setText10(!text10)}>
            <em>C&R:</em> {text10 && "How do efforts in resolving logic conflicts influence partners' perception about resource contributions and decisions about value shares and knowledge spillovers?"}
            </p>
            <p onClick={(e) => setText11(!text11)}>
            <em>MOC:</em> {text11 && "Which institutional regimes do minimize the threat of identity loss after the termination of XSPs?"}
            </p>
            <p onClick={(e) => setText12(!text12)}>
            <em>SSE:</em> {text12 && "Under which institutional arrangements would conveners be more able to marginalize third-party value claimants?"}
            </p>
          </td>
        </tr>
        <tr>
          <td><em><strong>Micro-economic behavior</strong> perspective interacting with other perspectives</em></td>
          <td>
          <p onClick={(e) => setText13(!text13)}>
            <em>IN:</em> {text13 && "Does developed trust reduce the consideration of negative institutional factors in the formation of XSPs?"}
            </p>
            <p onClick={(e) => setText14(!text14)}>
            <em>C&R:</em> {text14 && "Can perceived opportunism by potential partners influence the choice of resources they intend to bring to the partnership?"}
            </p>
            <p onClick={(e) => setText14(!text14)}>
            <em>MOC:</em> {text14 && "How do contractual arrangements and assumptions of opportunism affect partners' cognition of identity threat before the XSP formation?"}
            </p>
            <p onClick={(e) => setText15(!text15)}>
            <em>SSE:</em> {text15 && "How do inter-part trust, formalized agreements, and information asymmetry influence civil society actors' social evaluations of XSPs before formation?"}
            </p>
          </td>
          <td>
          <p onClick={(e) => setText16(!text16)}>
            <em>IN:</em> {text16 && "What contract designs and governance modes can mitigate the emergence of conflict between logics?"}
            </p>
            <p onClick={(e) => setText17(!text17)}>
              <em>C&R:</em> {text17 && "Under which conditions would partners' history of opportunism impede capability development within XSPs?"}
            </p>
            <p onClick={(e) => setText18(!text18)}>
            <em>MOC:</em> {text18 && "How do partners' ex-post hold-up and violation of terms influence perceptions of identity threat and emotional frictions?"}
            </p>
            <p onClick={(e) => setText19(!text19)}>
            <em>SSE:</em> {text19 && "To what extent do contract designs and governance modes affect the collaborative behavior of conveners?"}
            </p>
          </td>
          <td>
          <p onClick={(e) => setText20(!text20)}>
            <em>IN:</em> {text20 && "What contractual considerations can minimize detrimental institutional influences on value creation and capture?"}
            </p>
            <p onClick={(e) => setText21(!text21)}>
            <em>C&R:</em> {text21 && "How does efforts in establishing mutual trust may exacerbate the risks of knowledge spillovers?"}
            </p>
            <p onClick={(e) => setText23(!text23)}>
            <em>MOC:</em> {text23 && "How do contractual arrangements and assumptions of opportunism affect partners' cognition of identity threat after the termination of XSPs?"}
            </p>
            <p onClick={(e) => setText24(!text24)}>
            <em>SSE:</em> {text24 && "What type of contractual agreements with conveners can maximize the value disseminated to XSP social beneficiaries?"}
            </p>
          </td>
        </tr>
        <tr>
          <td><em><strong>Capabilities and resources</strong>perspective interacting with other perspectives</em></td>
          <td>
          <p onClick={(e) => setText25(!text25)}>
            <em>IN:</em> {text25 && "What partners' capabilities can reduce the negative effect of non-favorable institutional factors in the formation of XSPs?"}
            </p>
            <p onClick={(e) => setText26(!text26)}>
            <em>MEB:</em> {text26 && "Which partners' resource attributes can increase the perception that the partner will act opportunistically after the XSP formation?"}
            </p>
            <p onClick={(e) => setText27(!text27)}>
            <em>MOC:</em> {text27 && "Which resource attributes of potential partners can enhance employees' identity resonance with those partners and the consequent formed XSP?"}
            </p>
            <p onClick={(e) => setText28(!text28)}>
            <em>SSE:</em> {text28 && "What capabilities are needed to ensure supportive social evaluations of XSPs before their formation?"}
            </p>
          </td>
          <td>
          <p onClick={(e) => setText29(!text29)}>
            <em>IN:</em> {text29 && "How does the resource dependence (of partners and partnership) affect intensity and resolution of logic conflict?"}
            </p>
            <p onClick={(e) => setText30(!text30)}>
            <em>MEB:</em> {text30 && "How does resource complementarity and mutual dependence affect opportunism and trust in the management phase?"}
            </p>
            <p onClick={(e) => setText31(!text31)}>
            <em>MOC:</em> {text31 && "How does mutual learning and capability development within XSPs affect employees' professional and organizational identity?"}
            </p>
            <p onClick={(e) => setText32(!text32)}>
            <em>SSE:</em> {text32 && "To what extent can conveners motivate social evaluators to provide positive evaluations of partners and partnership?"}
            </p>
          </td>
          <td>
          <p onClick={(e) => setText33(!text33)}>
            <em>IN:</em> {text33 && "How does knowledge storage across multiple XSPs influence the quality of local and national institutions?"}
            </p>
            <p onClick={(e) => setText34(!text34)}>
            <em>MEB:</em> {text34 && "When can efforts to avoid knowledge spillover motivate partners to behave opportunistically in dissemination of value?"}
            </p>
            <p onClick={(e) => setText35(!text35)}>
            <em>MOC:</em> {text35 && "How does the blocking of knowledge spillover affect partners' emotions and incentives to scale up the XSP?"}
            </p>
            <p onClick={(e) => setText36(!text36)}>
            <em>SSE:</em> {text36 && "Under what conditions can accrued capabilities in tackling problematic social evaluations of an XSP spill over to future XSPs?"}
            </p>
          </td>
        </tr>
        <tr>
          <td><em><strong>Managerial and organizational cognition</strong> perspective interacting with other perspectives</em></td>
          <td>
          <p onClick={(e) => setText37(!text37)}>
            <em>IN:</em> {text37 && "To what extent do identity flexibility and mature communication compensate the negative role of institutional complexity in XSP formation?"}
            </p>
            <p onClick={(e) => setText38(!text38)}>
            <em>MEB:</em> {text38 && "What cognitive processes shape perceptions of opportunism and trustworthiness, and related design choices before the formation?"}
            </p>
            <p onClick={(e) => setText39(!text39)}>
            <em>C&R:</em> {text39 && "Which types of communication approaches can foster or hinder the disclosure of resources by partners?"}
            </p>
            <p onClick={(e) => setText40(!text40)}>
            <em>SSE:</em> {text40 && "How does identity overlap among partners influence social evaluations of the partnership by third-party evaluators?"}
            </p>
          </td>
          <td>
          <p onClick={(e) => setText41(!text41)}>
            <em>IN:</em> {text41 && "How do employees' emotional experiences can influence the dominance of a given logic within XSPs?"}
            </p>
            <p onClick={(e) => setText42(!text42)}>
            <em>MEB:</em> {text42 && "How does maintaining identity plurality affect opportunism and trust in the management phase?"}
            </p>
            <p onClick={(e) => setText43(!text43)}>
            <em>C&R:</em> {text43 && "To what extent partners' identities and cognition affect resource sharing and mutual learning?"}
            </p>
            <p onClick={(e) => setText44(!text44)}>
            <em>SSE:</em> {text44 && "To what extent can conveners' identities and values influence social evaluations of partners and partnership?"}
            </p>
          </td>
          <td>
          <p onClick={(e) => setText45(!text45)}>
            <em>IN:</em> {text45 && "How does identity loss after the XSP termination influence the likelihood and type of future XSPs?"}
            </p>
            <p onClick={(e) => setText46(!text46)}>
            <em>MEB:</em> {text46 && "When do efforts to establish collaborative communication context backfire and increase partners' opportunism in value dissemination?"}
            </p>
            <p onClick={(e) => setText47(!text47)}>
            <em>C&R:</em> {text47 && "How does emotion regulation processes affect partners' incentives to share value with social beneficiaries?"}
            </p>
            <p onClick={(e) => setText48(!text48)}>
            <em>SSE:</em> {text48 && "Under what conditions can maintained or altered identity positively influence social evaluators' incentives to capture XSP value?"}
            </p>
          </td>
        </tr>
        <tr>
          <td><em><strong>Stakeholder and social evaluations</strong> perspective interacting with other perspectives</em></td>
          <td>
          <p onClick={(e) => setText49(!text49)}>
            <em>IN:</em> {text49 && "How does social activism by partners' stakeholders affect political and institutional uncertainty for XSP formation?"}
            </p>
            <p onClick={(e) => setText50(!text50)}>
            <em>MEB:</em> {text50 && "How do positive social evaluations influence partners' perceptions of opportunism and trustworthiness?"}
            </p>
            <p onClick={(e) => setText51(!text51)}>
            <em>C&R:</em> {text51 && "Which types of conveners can increase or decrease the importance of partners' complementary resources for XSP formations?"}
            </p>
            <p onClick={(e) => setText52(!text52)}>
            <em>MOC:</em> {text52 && "How do the identities of social evaluators influence employees' perceptions of identity threat before XSP formation?"}
            </p>
          </td>
          <td>
          <p onClick={(e) => setText53(!text53)}>
            <em>IN:</em> {text53 && "How does conveners' legitimacy work can facilitate or constrain partners' efforts to resolve logic conflicts within XSPs?"}
            </p>
            <p onClick={(e) => setText54(!text54)}>
            <em>MEB:</em> {text54 && "How do positive vs. negative social evaluations affect opportunistic behavior within XSPs?"}
            </p>
            <p onClick={(e) => setText55(!text55)}>
            <em>C&R:</em> {text55 && "How do social evaluations affect the capacity to effectively mobilize and integrate complementary resources?"}
            </p>
            <p onClick={(e) => setText56(!text56)}>
            <em>MOC:</em> {text56 && "To what extent can conveners' legitimacy in the eyes of partners influence employees' perception about conveners' emotion regulation efforts?"}
            </p>
          </td>
          <td>
          <p onClick={(e) => setText57(!text57)}>
            <em>IN:</em> {text57 && "How does stakeholder marginalization in value capture influence the scaling of different types of XSPs?"}
            </p>
            <p onClick={(e) => setText58(!text58)}>
            <em>MEB:</em> {text58 && "When does convenors' opportunistic behavior in value creation phase increase partners' opportunism in value dissemination?"}
            </p>
            <p onClick={(e) => setText59(!text59)}>
            <em>C&R:</em> {text59 && "How do positive social evaluations and partners' legitimacy influence the intentions for knowledge spillover beyond the XSP scope?"}
            </p>
            <p onClick={(e) => setText60(!text60)}>
            <em>MOC:</em> {text60 && "How do negative social evaluations of certain partners' professional identities influence the success of XSPs?"}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
  )
}

export default FutureQuestions