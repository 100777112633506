import React, { useState, useEffect } from "react"
import { Line } from "react-chartjs-2"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const getLineGraphData = (data) => {
    console.log(data)
    const groupedYearData = data.reduce((acc, curr) => {
      acc[curr["Year"]] = (acc[curr["Year"]] || 0) + 1
      return acc
    }, {})
  
    console.log(groupedYearData)
  
    const labels = Object.keys(groupedYearData).filter((key) => key !== "").sort()
    console.log(labels)
    const counts = labels.map(label => groupedYearData[label])
    console.log(counts)

    if (labels.length === 0 || counts.length === 0) {
        return null;
      }

  
    return{
      labels,
      datasets: [
        {
          label: "Publications by Year",
          data: counts,
          fill: false,
          backgroundColor: '#ffd166',
          borderColor: '#ffda85'
        }
      ]
    }
}

const LineGraph = ({data}) => {
    const lineData = getLineGraphData( data)
    console.log(lineData)
    return (
        <div>
            <Line
        data={lineData}
        options={{
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              font: {
                size: 20
              }
            }
          },
          y: {
            ticks: {
              font: {
                size: 20
              }
            }
          }
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: 'Publications by Year',
            font: {
              size: 30
            }
          }
        },
        maintainAspectRatio: false,
        responsive: false,

      }} 
      height={1000}
      width={1000}/>

        </div>
    )
}

export default LineGraph